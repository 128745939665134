import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    styled,
    Typography,
    Button,
    Card,
    CardContent,
    Chip
} from "@mui/material";
import { DashboardHeaderWeb as DashboardHeader } from "../../dashboard/src/DashboardHeader.web";
import { backArrow, blankCheckbox, selectedCheckbox, addQuestionImg, editQuestion, deleteQuestion } from './assets';
import { Questions } from "./ListingQuestionBankController";
// Customizable Area End

import ListingQuestionBankController, {
    Props
} from "./ListingQuestionBankController";

export default class ListingQuestionBank extends ListingQuestionBankController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    addQuestionsBtn = () => {
        return (
            <Grid item xs={12} sm={7} md={12}>
                <Button
                    className="addQuestionBtn"
                    data-test-id="create_dictionary"
                >
                    <Box className="addQuestionBox">
                        <img src={addQuestionImg.default} height="20px" width="20px" />
                        <Typography className="addQuestionText">
                            Add Questions
                        </Typography>
                    </Box>

                </Button>
            </Grid>
        )
    }

    questionList = (item: Questions, index: number) => {
        const data = Array.isArray(item.data) ? item.data[0] : item.data;
        const attributes = data?.attributes;
        const cleanedText = attributes.text.replace(/Question:\s*/g, "").trim();

        const questionTypes = this.state.questionBankData.data?.attributes.selected_question_types || [];
        const questionFormats = this.state.questionBankData.data?.attributes.selected_question_formats || [];
        const difficultyLevel = this.state.questionBankData.data?.attributes.selected_difficulty_levels || [];
        const focusArea = this.state.questionBankData.data?.attributes.selected_focus_areas || [];

        const combinedData = [
            ...questionTypes,
            ...questionFormats,
            ...difficultyLevel,
            ...focusArea
        ];
        return (
            <Wrapper sx={{ marginTop: 0 }}>
                {!this.state.previewClicked &&
                    <Grid container spacing={1.5} alignItems={"center"}>
                        <Grid item xs={12} sm={"auto"} className="filterEditRemove">
                            <img
                                src={blankCheckbox.default}
                                alt="checkbox"
                                className="checkBoxStyle"
                            />
                        </Grid>

                        <Grid item xs={12} sm={10.5} className="chipGridItem">
                            {combinedData.map((filter, index) => (
                                <Chip
                                    variant="outlined"
                                    size="small"
                                    label={filter}
                                    className={`filterChip ${index === 0 ? "filterChipSelected" : null}`}
                                />
                            ))}
                        </Grid>

                        <Grid item xs={12} sm={"auto"} className="filterEditRemove">
                            <img
                                src={editQuestion.default}
                                width={"24px"}
                                height={"24px"}
                                style={{ marginRight: "5px" }} />
                            <img
                                src={deleteQuestion.default}
                                data-test-id="delete_question"
                                onClick={()=>this.deleteQuestionApiCall(attributes.id)}
                                width={"24px"}
                                height={"24px"} />
                        </Grid>
                    </Grid>
                }
                <Box sx={{
                    margin: {
                        xs: "24px 0px 24px 8px",
                        sm: "24px 0px 24px 16px",
                        md: "24px 0px 24px 40px"
                    }
                }}>
                    <Typography className="questionLabel">
                        {index}. {cleanedText}
                    </Typography>

                    <Box sx={{ fontFamily: 'Urbanist' }}>
                        {attributes.answer.choices.map((options) => {
                            const cleanedOptionText = options.option_text.replace(/^[a-d]\)\s*/, "");
                            return (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    mb={1}
                                    className={(options.correct && !this.state.previewClicked) ? "optionsStyleTrue" : "optionsStyle"}
                                    sx={{ fontFamily: 'Urbanist' }}
                                    data-test-id="question_options"
                                >
                                    <img
                                        src={(options.correct && !this.state.previewClicked) ? selectedCheckbox.default : blankCheckbox.default}
                                        alt="checkbox"
                                        className="checkBoxStyle"
                                    />
                                    <Typography>{cleanedOptionText}</Typography>
                                </Box>
                            )
                        })}
                    </Box>
                </Box>

            </Wrapper>
        )
    }

    saveChangesButton = () => {
        return (
            <Wrapper sx={{ marginTop: "20px" }}>
                <Button
                    className="addQuestionBtn"
                    data-test-id="cancel_btn"
                >
                    <Typography className="addQuestionText">
                        Save Changes
                    </Typography>
                </Button>
            </Wrapper>
        )
    }

    cancelQuestionButton = () => {
        return (
            <Wrapper sx={{ marginTop: "20px" }}>
                <Button
                    className="addQuestionBtn"
                    data-test-id="cancel_btn"
                >
                    <Typography className="addQuestionText">
                        Cancel
                    </Typography>
                </Button>
            </Wrapper>
        )
    }

    previewButton = () => {
        return (
            <Wrapper sx={{ marginTop: "20px" }}>
                <Button
                    className="previewBtn"
                    data-test-id="preview_btn"
                    onClick={() => this.scrollToSection(this.previewRef)}
                >
                    <Typography className="previewBtnText">
                        Preview
                    </Typography>
                </Button>
            </Wrapper>
        )
    }

    questionListCard = () => {
        return (
            <Wrapper>
                {!this.state.loader &&
                    <Card sx={{ borderRadius: 2, boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px" }}>
                        <CardContent sx={{ paddingLeft: "40px", paddingBottom: "0px !important" }}>
                            <Grid container spacing={1} className="contentPadding">
                                {this.state.questionBankData.data?.attributes.questions.map((item, index) => (
                                    <Grid item xs={12} key={index + 1}>
                                        {this.questionList(item, index + 1)}
                                    </Grid>
                                ))}
                                {!this.state.previewClicked &&
                                    <>
                                        <Grid item xs={12} md={4} >
                                            {this.saveChangesButton()}
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            {this.cancelQuestionButton()}
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            {this.previewButton()}
                                        </Grid>
                                    </>
                                }
                            </Grid>

                        </CardContent>
                    </Card>
                }
            </Wrapper>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="Question banks" navigation={this.props.navigation} id={this.props.id}>
                <MainWrapper ref={this.previewRef}>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={11}>
                            <Grid container alignItems={"center"} justifyContent={"space-between"} spacing={1}>
                                <Grid item xs={11} sm={11} md={7}>
                                    <Grid container spacing={1} alignItems={"center"}>
                                        <Grid item xs={12} sx={{
                                            display: "flex",
                                            alignItems: "center"
                                        }}>
                                            <img src={backArrow.default}
                                                alt="Back Arrow"
                                                className="backArrowImg"
                                                onClick={this.state.previewClicked ?
                                                    this.navigateToListScreen : this.navigateToCreation}
                                                data-test-id="back_to_dashboard"
                                            />
                                            {!this.state.loader &&
                                                <Typography className="questionTitle">
                                                    {this.state.previewClicked ?
                                                        "Generated Questions" :
                                                        `${this.state.questionBankData.data?.attributes.questions.length} Generated Questions`
                                                    }
                                                </Typography>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={11} sm={11} md={5} lg={2.5}
                                    sx={{
                                        display: "flex",
                                        justifyContent: {
                                            xs: "flex-start",
                                            md: "flex-end",
                                            lg: "flex-end",
                                            xl: "flex-end"
                                        },
                                        marginRight: "16px"
                                    }}
                                >
                                    {!this.state.previewClicked &&
                                        <Grid container spacing={2}>
                                            {this.addQuestionsBtn()}
                                        </Grid>
                                    }

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={11}>
                            {this.questionListCard()}
                        </Grid>
                    </Grid>
                </MainWrapper>
            </DashboardHeader>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const MainWrapper = styled(Box)({
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "42px",
    paddingBottom: "30px",
    "& .backArrowImg": {
        paddingRight: "12px"
    },
    "& .questionTitle": {
        fontFamily: "Urbanist",
        display: "inline",
        fontSize: "25px",
        fontWeight: 600,
        color: "#000104",
        marginLeft: "8px",
    },
    "& .addQuestionBox": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    "& .addQuestionText": {
        fontWeight: 700,
        fontFamily: "Urbanist",
        color: "#044352",
        fontSize: "16px",
        lineHeight: "24px",
        textTransform: "none",
        marginLeft: "5px",
    },
    "& .addQuestionBtn": {
        width: "100%",
        height: "58px",
        borderRadius: "4px",
        border: "1px solid #044352",
        "&:hover": {
            background: "none"
        }
    },
    "& .previewBtn": {
        height: "58px",
        padding: "10px 16px 10px 16px",
        border: "1px solid #94A3B8",
        width: "100%",
        borderRadius: "4px",
        background: "#044352"
    },
    "& .previewBtnText": {
        lineHeight: "24px",
        fontFamily: "Urbanist",
        color: "#FFFFFF",
        fontWeight: 700,
        fontSize: "16px",
        textTransform: "none"
    },
})

export const Wrapper = styled(Box)({
    width: "100%",
    marginTop: "54px",
    "& .questionLabel": {
        fontSize: "16px",
        color: "#0F172A",
        fontFamily: "Urbanist",
        fontWeight: 700,
        linHheight: "22px",
        marginBottom: "20px"
    },
    "& .contentPadding": {
        padding: "10px 30px 40px 0px"
    },
    "& .checkBoxStyle": {
        width: "20px",
        marginRight: "10px",
        height: "20px",
    },
    "& .chipGridItem": {
        alignItems: "center",
        display: "flex",
        flexWrap: "wrap"
    },
    "& .optionsStyle": {
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Urbanist',
        cursor: "pointer",
        color: "#000000",
        width: "93%",
        "&:hover": { backgroundColor: "transparent" },
        border: '1px solid #E2E8F0',
        height: '50px',
        lineHight: "24px",
        textAlign: "left",
        borderRadius: '8px',
        padding: '0px 16px',
        "& .MuiTypography-root": {
            fontWeight: 400,
            fontFamily: 'Urbanist',
            fontSize: "14px",
            linHheight: "22px",
        },
    },
    "& .optionsStyleTrue": {
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Urbanist',
        cursor: "pointer",
        color: "#000000",
        width: "93%",
        border: "1px solid #059669",
        backgroundColor: "#D1FAE5",
        "&:hover": { backgroundColor: "#D1FAE5" },
        height: '50px',
        lineHight: "24px",
        textAlign: "left",
        borderRadius: '8px',
        padding: '0px 16px',
        "& .MuiTypography-root": {
            fontWeight: 400,
            fontFamily: 'Urbanist',
            fontSize: "14px",
            linHheight: "22px",
        },
    },
    "& .filterChip": {
        margin: "2px",
        padding: "14px 4px",
        color: "#475569",
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "22px",
        border: "1px solid #E2E8F0",
        display: "flex"
    },
    "& .filterChipSelected": {
        color: "#DC2626",
        border: "1px solid #DC2626",
    },
    "& .filterEditRemove": {
        display: "flex",
        justifyContent: "center"
    }
})
// Customizable Area End