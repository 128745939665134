import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import React, { RefObject } from "react";
interface DeleteQuestionResponse {
    message?: string;
    error?: string;
}
interface QuestionBankResponse {
    message: string;
    error: string;
    question_bank: QuestionBankData;
}
interface QuestionBankData {
    data?: {
        id: string;
        type: string;
        attributes: {
            selected_question_types: string[];
            selected_question_formats: string[];
            selected_difficulty_levels: string[];
            selected_focus_areas: string[];
            questions: Questions[];
        }
    }
}
export interface Questions {
    data: Array<{
        id: string;
        type: string;
        attributes: {
            id: string;
            text: string;
            answer_type: string;
            question_bank_id: string;
            answer: {
                id: string;
                answer_type: string;
                choices: {
                    id: string;
                    option_text: string;
                    correct: boolean;
                }[];
                correct_choices: {
                    id: string;
                    option_text: string;
                    correct: boolean;
                }[];
            };
        };
    }>;
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    selectedLanguage: string;
    token: string;
    questionBankId: string;
    questionBankData: QuestionBankData;
    deleteQuestionData: DeleteQuestionResponse;
    errorMsg: string;
    previewClicked: boolean;
    loader: boolean;
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ListingQuestionBankController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getQuestionListApiCallId: string = "";
    deleteQuestionApiCallId: string = "";
    previewRef: RefObject<HTMLElement> = React.createRef<HTMLElement>();
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            selectedLanguage: "",
            token: "",
            errorMsg: "",
            questionBankId: "",
            questionBankData: {},
            deleteQuestionData: {},
            previewClicked: false,
            loader: false
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getQuestionListApiCallId) {
                this.questionListApiResponse(responseJson)
            }
            if (apiRequestCallId === this.deleteQuestionApiCallId) {
                this.deleteQuestionApiResponse(responseJson)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();

        const signInResponse = await getStorageData("signInResponse");
        const parsedSignInResponse = await JSON.parse(signInResponse)
        this.setState({ token: parsedSignInResponse.meta?.token })

        const questionBankId = await getStorageData("questionBankId");
        const parsedId = await JSON.parse(questionBankId)
        this.setState({ questionBankId: parsedId }, () => {           
        this.QuestionListApiCall()
        });

        const language = await getStorageData("language") || "English";
        this.setState({ selectedLanguage: language });
    }

    questionListApiResponse = (responseJson: QuestionBankResponse) => {
        if (responseJson && !responseJson.error) {
            this.setState({
                questionBankData: responseJson.question_bank,
                loader: false
            })
        } else {
            this.setState({
                errorMsg: responseJson.error,
                loader: false
            });
        }
    }
    deleteQuestionApiResponse = (responseJson: DeleteQuestionResponse) => {
        if (responseJson && !responseJson.error) {
            this.setState({
                deleteQuestionData: responseJson
            }, () => {
                this.QuestionListApiCall();
            })
        } else {
            this.setState({
                errorMsg: responseJson.error || "",
                
            });
        }
    }


    QuestionListApiCall = () => {
        this.setState({ loader: true })
        const headers = {
            "token": this.state.token
        };
        const getQuestionListMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getQuestionListApiCallId = getQuestionListMsg.messageId;
        getQuestionListMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getQuestionListMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.questionListApiEndPoint}/${this.state.questionBankId}`
        );
        getQuestionListMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getQuestionListMsg.id, getQuestionListMsg);
    }
    
    deleteQuestionApiCall = (questionID: string) => {
        const headers = {
            "token": this.state.token
        };
        const deleteQuestionMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteQuestionApiCallId = deleteQuestionMsg.messageId;
        deleteQuestionMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        deleteQuestionMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.questionListApiEndPoint}/${this.state.questionBankId}/delete_question?question_id=${questionID}`
        );
        deleteQuestionMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethodType
        );
        runEngine.sendMessage(deleteQuestionMsg.id, deleteQuestionMsg);
    }

    navigateToListScreen = () => {
        this.setState({ previewClicked: !this.state.previewClicked })
    }

    scrollToSection = (ref: React.RefObject<HTMLElement>) => {
        this.setState({ previewClicked: true }, () => {
            if (ref.current) {
                ref.current.scrollIntoView({ behavior: 'smooth' });
            }
        })

    };

    navigateToCreation = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "QuestionBankCreation");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }
    // Customizable Area End
}