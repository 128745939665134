import React from "react";

// Customizable Area Start
import {     
  Box,
  Grid,
  styled,
  Typography,
  Card,
  CardContent,
  Divider,
  Select,
  InputBase,
  MenuItem,
  Button,
  Modal, 
  TextField,
  InputAdornment,
  IconButton,
  alpha,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  LinearProgress } from "@mui/material";
  import SearchIcon from '@mui/icons-material/Search';
  import CloseIcon from '@mui/icons-material/Close';
  import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {  DashboardHeaderWeb as DashboardHeader  } from "../../dashboard/src/DashboardHeader.web";
import {arrow_Left, calendar, deleteIcon, progress, right } from "./assets";
import CustomPopup from "../../../components/src/CustomSuccessModel.web";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { withTranslation } from "react-i18next";
import Calendar from "react-calendar";
import ClickAwayListener from '@mui/material/ClickAwayListener';
// Customizable Area End

import Cfchatgptintegration9Controller, {
  Props,
  configJSON,
} from "./Cfchatgptintegration9Controller";

export default class Cfchatgptintegration9 extends Cfchatgptintegration9Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  jobDescValidationSchema = Yup.object({
    jobTitle: Yup.string().required(this.TraslationFuncation("RequiredField")),
    DepartmentTeam: Yup.string().required(this.TraslationFuncation("RequiredField")),
    jobLevel: Yup.string().required(this.TraslationFuncation("RequiredField")),
    jdStyle: Yup.string().required(this.TraslationFuncation("RequiredField")),
    jobFamily: Yup.string().required(this.TraslationFuncation("RequiredField")),
    jobSummary: Yup.string().required(this.TraslationFuncation("RequiredField")),  
    keySkills: Yup.string().required(this.TraslationFuncation("RequiredField")),
    keyWords: Yup.string().required(this.TraslationFuncation("RequiredField"))
  });

  emptyDataScreen = () => {
    return (
      <MainWrapper>
        <Grid container justifyContent={"center"}>
          <Grid item xs={11}>
            <Grid container spacing={1} alignItems={"center"}>
              <Grid item xs={12} sm={12} md={11.2} lg={11.5}>
                <Wrapper>
                  <Box className="progressImage">
                    <img src={progress.default} />
                  </Box>
                  <Typography className="emptyText">{this.TraslationFuncation("NoData")}</Typography>
                  <Typography className="emptyScreenMessage">
                    {this.TraslationFuncation("Itlookslikethere")}
                  </Typography>
                  <Typography className="emptyScreenMessage">
                    {this.TraslationFuncation("efficiency")}
                  </Typography>
                  {
                    this.state.userdetailsData?.length === 0 &&
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Button data-test-id="createNewJob" className="jobCreationbutton" onClick={this.createNewJob}>
                          <Typography className="jobCreationText">{this.TraslationFuncation("JobDescriptionPage.CreateNewJob")}</Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  }
                </Wrapper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MainWrapper>
    )
  }

  loaderScreen = () => {
    return (
      <>
         <Box className="loader-outer"
                  sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
                  <Box className="loader-inner">
                      <CircularProgress />
                  </Box>
        </Box>
      </>
    )
  }

  progressDataScreen = () => {
    return (
      <Grid container justifyContent={"center"} style={{ direction:"ltr" }} >
        <Grid item xs={11}>
          <Grid container spacing={1} alignItems={"center"}>
            <Grid item xs={1.5} sm={1} md={0.8} lg={0.5}  >
              <Box data-test-id="backToForm" onClick={this.backToForm}
                style={{
                  width: "47px", height: "47px", border: "1px solid #ECECEC", borderRadius: "50%",
                  display: "flex", alignItems: "center", justifyContent: "center"
                }}
              >
                <img src={arrow_Left.default}
                  alt="Back Arrow"
                />
              </Box>
            </Grid>
            <Grid item xs={10.5} sm={11} md={11.2} lg={11.5}>
              <Typography className="jobTitle">{this.TraslationFuncation("JobDescriptionPage.GeneratingJobDescription")}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1} className={"jobMainHeaderGrid"}>
            <Grid item xs={12} sm={12} md={11.2} lg={11.5}>
              <Wrapper>
                <Box className="progressMainContainer">
                  <Box className="progressContainer">
                    <Box className="progressImage">
                      <img src={progress.default} />
                    </Box>
                    <Box className="dotsProgress">
                      {[0, 1, 2, 3, 4, 5, 6].map((obj, index) => <Box key={index} style={{
                        width: "7.5px", height: "7.5px",
                        borderRadius: "50%", backgroundColor: "#059669",
                        transform: (index === this.state.active, "scale(1.5)", "scale(1)")
                      }}></Box>)}
                    </Box>
                    <Box className="linearProgressBar">
                      <LinearProgress
                        className="linearProgress"
                        variant="determinate"
                        value={this.state.progress}
                      />
                    </Box>
                    <Box className="ProgressTextContain">
                      <Typography className="progressTextRunning">
                        {this.state.progress}{this.TraslationFuncation("JobDescriptionPage.OnePercentCompleteFinalisingJobDescription")}
                      </Typography>
                    </Box>
                    <Box className="ProgressCreationContent">
                      <Typography className="progressContent1">
                        {this.TraslationFuncation("JobDescriptionPage.HangTightJobDescriptionCreationInProgress")}
                      </Typography>
                      <Typography className="progressContent2">
                        {this.TraslationFuncation("JobDescriptionPage.WaitWeGenerateDescription")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Wrapper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  
  jobDetailDataScreen = () => {        
    const keySkillData = this.state.ShowJobDescriptChatGpt.key_skills.map((skill:string) => skill.replace(/^- /, '').trim()) 
  .filter(Boolean);

const keyRespoData = this.state.ShowJobDescriptChatGpt.key_responsibilities
  ?.map((responsibility: string) => responsibility.replace(/^- /, '').trim())
  .filter(Boolean);

  const keyWordsData = this.state.ShowJobDescriptChatGpt.key_words.join(", ") .split(",") .map((keyword: string) => keyword.trim())
  .filter(Boolean);

    return (
      <Grid container justifyContent={"center"}>
      <Grid xs={11} >
          <Grid container spacing={1} alignItems={"center"}>
              <Grid item xs={1.5} sm={1} md={0.8} lg={0.5}  >
              <Box data-test-id="backToViewDetails" onClick={()=> this.handleScreen()}
              style={{width:"47px" , height:"47px", border:"1px solid #ECECEC" , borderRadius:"50%",
              display: "flex", alignItems: "center", justifyContent: "center",transform:  this.ConditionForLanguage("","rotate(180deg)") 
              }}
                >
                  <img src={arrow_Left.default} alt="Back Arrow"  />
                </Box>
              </Grid>
              <Grid item xs={10.5} sm={11} md={11.2} lg={11.5} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                  <Typography className="jobTitle" style={{ marginRight:this.ConditionForLanguage("","12px") }} >{this.TraslationFuncation("JobDescriptionPage.JobDescription")}</Typography>
                  <Grid 
                  item 
                  xs={12} sm={12} md={5} 
                  display={"flex"} 
                  gap={3} 
                  justifyContent={{ xs: "center", md: "flex-end" }} 
                  sx={{
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'stretch', sm: 'center' },
                    mr: { sm: 0, md: '5rem' },
                  }}
                >
                <Select
                  sx={{
                    backgroundColor: "#044352",
                    width: { xs: '100%', sm: '100%' },
                    height: "58px",
                    padding: "10px 16px",
                    borderRadius: "4px",
                    "& .MuiSelect-icon": {
                      color: "#FFFFFF",
                      transform: 'none !important',
                    },
                  }}
                  data-test-id="setDownload"
                  className="downloadButton"
                  variant="outlined"
                  name="download"
                  displayEmpty
                  value={this.state.selectedOption}
                  onChange={this.handleChange}
                  IconComponent={KeyboardArrowRightIcon}
                  input={<InputBase />}
                  renderValue={() => (
                    <span style={{
                        color: '#FFFFFF',
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontFamily: "Urbanist",
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "28px",
                      }}>
                        {this.TraslationFuncation("JobDescriptionPage.Download")}
                      </span>
                  )}
                >
                  {configJSON.downloadDropDownData?.map((option: string, index: number) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
                  <Button 
                    data-test-id="JobDetail"
                    className="editButton" 
                    variant="outlined" 
                    sx={{
                      backgroundColor: "#044352",
                      width: { xs: '100%', sm: '100%' },
                      height: "58px",
                      padding: "10px 16px",
                      borderRadius: "4px",
                      textTransform:"none",
                      "&:hover": {
                        backgroundColor: "#044352",
                      },
                      "& .MuiTypography-root": {
                        fontFamily: "Urbanist",
                        fontSize: "16px",
                        fontWeight: 500,
                        lineHeight: "28px",
                        color: "#FFFFFF",
                      }
                    }}
                    onClick={()=> this.setState({editJobDetailScreen:true , jobDetailScreen:false})}
                  >
                    <Typography className="editButtonText" style={{ fontWeight:700 }}>{this.TraslationFuncation("JobDescriptionPage.EditJobDetails")}</Typography>
                  </Button>
                </Grid>
              </Grid>
                <Grid item xs={12} sm={12} md={11.2} lg={11.5} className="jobDetailHeaderGrid">
                  <Wrapper>
                <Box className="companyItemMainBox">
                  <Grid container className={"companyCardContainer"} justifyContent={"flex-start"}>
                    <Grid item xs={12} className="companyCardItem">
                      <Card className="jobdetailBox">
                        <CardContent 
                        className="cardContentPadding"><Grid 
                          container> <Grid item xs={12} className="orgDetailPadding">
                              <Typography className="JobDetailTitle">{this.state.ShowJobDescriptChatGpt.position}
                              </Typography></Grid>
                            <Grid item xs={12}><Divider
                              sx={{ color:"#E2E8F0",width: 'calc(100% - 35px)',marginRight: '35px',}} /></Grid></Grid>
                          <Grid container spacing={3.5} className="contentPadding"><Grid item xs={12} md={6}>
                              <Wrapper>
                                <Box className="errorStyleMainbox">
                                  <Typography className="JobDetailLabel">
                                    {this.TraslationFuncation("JobDescriptionPage.JobID")}: {this.state.viewedId}
                                  </Typography>
                                </Box>
                                <Typography className="JobDetailContent">
                                  {this.TraslationFuncation("JobDescriptionPage.JobTitle")} {this.state.ShowJobDescriptChatGpt.position}
                                </Typography>
                                <Typography className="JobDetailContent">
                                  {this.TraslationFuncation("JobDescriptionPage.DepartmentTeam")}  {this.state.ShowJobDescriptChatGpt.department}
                                </Typography>
                              </Wrapper>
                            </Grid>
                          </Grid>
                          <Grid item xs={11.65} marginTop={"25px"}>
                            <Divider
                            sx={{ 
                              color:"#E2E8F0",
                              width: 'calc(100% - 35px)',
                              marginLeft: '35px',
                            }} 
                            />
                          </Grid>
                          <Grid container spacing={3.5} className="contentPadding">
                            <Grid item xs={10.9}>
                              <Wrapper>
                                <Box className="errorStyleMainbox">
                                  <Typography className="JobDetailLabel">
                                    {this.TraslationFuncation("JobDescriptionPage.JobLevel")}
                                  </Typography>
                                </Box>
                                <Typography className="JobDetailContent"> {this.state.ShowJobDescriptChatGpt.job_level} </Typography>
                              </Wrapper>
                            </Grid>
                          </Grid>
                          <Grid item xs={11.65} marginTop={"25px"}>
                            <Divider
                            sx={{ 
                              color:"#E2E8F0",
                              width: 'calc(100% - 35px)',
                              marginLeft: '35px',
                            }}  />
                          </Grid>
                          <Grid container spacing={3.5} className="contentPadding">
                            <Grid item xs={11}>
                              <Wrapper>
                                <Box className="errorStyleMainbox">
                                  <Typography className="JobDetailLabel">
                                    {this.TraslationFuncation("JobDescriptionPage.JobExperience")}
                                  </Typography>
                                </Box>
                                <Typography className="JobDetailContent">
                                {this.state.ShowJobDescriptChatGpt.job_experience}
                                </Typography>
                              </Wrapper>
                            </Grid>
                          </Grid>
                          <Grid item xs={11.65} marginTop={"25px"}>
                            <Divider
                            sx={{ 
                              color:"#E2E8F0",
                              width: 'calc(100% - 35px)',
                              marginLeft: '35px',
                            }} 
                            />
                          </Grid>
                          <Grid container spacing={3.5} className="contentPadding">
                            <Grid item xs={11}>
                              <Wrapper>
                                <Box className="errorStyleMainbox">
                                  <Typography className="JobDetailLabel"> {this.TraslationFuncation("JobDescriptionPage.KeyResponsibilities")} </Typography>
                                </Box>
                                {keyRespoData?.length > 0 && keyRespoData?.map((respo: string, index: number)=> (
                          <Typography className="JobDetailContent" key={index}>
                            {index + 1}. {respo}
                            </Typography>
                                ))}
                              </Wrapper>
                            </Grid>
                          </Grid>
                          <Grid item xs={11.65} marginTop={"25px"}>
                            <Divider
                            sx={{ 
                              color:"#E2E8F0", width: 'calc(100% - 35px)', marginLeft: '35px',
                            }} 
                            />
                          </Grid>
                          <Grid container spacing={3.5} className="contentPadding">
                            <Grid item xs={11}>
                              <Wrapper>
                                <Box className="errorStyleMainbox">
                                  <Typography className="JobDetailLabel"> {this.TraslationFuncation("JobDescriptionPage.KeySkills")}</Typography>
                                </Box>
                                {keySkillData?.length > 0 && keySkillData?.map((skills: string, index: number) => (
                                  <Typography className="JobDetailContent" key={index}>{index + 1}. {skills} </Typography>
                                ))}
                              </Wrapper>
                            </Grid>
                          </Grid>
                          <Grid item xs={11.65} marginTop={"25px"}>
                            <Divider
                            sx={{ 
                              color:"#E2E8F0",
                              width: 'calc(100% - 35px)',
                              marginLeft: '35px',
                            }} 
                            />
                          </Grid>
                          <Grid container spacing={3.5} className="contentPadding">
                            <Grid item xs={11}>
                              <Wrapper>
                                <Box className="errorStyleMainbox">
                                  <Typography className="JobDetailLabel">
                                    {this.TraslationFuncation("JobDescriptionPage.KeyWords")}
                                  </Typography>
                                </Box>
                                {keyWordsData?.length > 0 && keyWordsData?.map((word: string, index: number) => (
        <Typography className="JobDetailContent" key={index}>
          {index + 1}. {word}
        </Typography>
      ))}
                              </Wrapper>
                            </Grid>
                          </Grid>
                          <Grid item xs={11.65} marginTop={"25px"}>
                            <Divider
                            sx={{ 
                              color:"#E2E8F0",
                              width: 'calc(100% - 35px)',
                              marginLeft: '35px',
                            }} 
                            />
                          </Grid>
                          <Grid container spacing={3.5} className="contentPadding">
                            <Grid item xs={11} marginBottom={"60px"}>
                              <Wrapper>
                                <Box className="errorStyleMainbox">
                                  <Typography className="JobDetailLabel">
                                    {this.TraslationFuncation("JobDescriptionPage.JobSummary")}
                                  </Typography>
                                </Box>
                                <Typography className="JobDetailContent">
                                 {this.state.ShowJobDescriptChatGpt.job_summary}
                                </Typography>
                              </Wrapper>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Box>
                  </Wrapper>
                  </Grid>
          </Grid>
      </Grid>
    </Grid>
    )
  }

  editJobDetailDataScreen = () => {
    const {
      EditJobTitle,
      EditDepartmentTeam,
      EditJobLevel,
      EditJobExperience,
      EditJobFamily,
      EditJobSummary,
      EditKeySkills,
      EditKeyWords,
    } = this.state
    return (
      <Grid container justifyContent={"center"} style={{ direction:"ltr" }} >
        <Grid item xs={11}>
          <Grid container spacing={1} alignItems={"center"}>
            <Grid item xs={1.5} sm={1} md={0.8} lg={0.5}  >
              <Box data-test-id="editJobDetail"
              style={{width:"47px" , height:"47px", border:"1px solid #ECECEC" , borderRadius:"50%",
                  display: "flex", alignItems: "center", justifyContent: "center"
                }}
              onClick={()=> this.setState({editJobDetailScreen:false , jobDetailScreen:true})}
              >
                <img src={arrow_Left.default}
                  alt="Back Arrow"
                />
              </Box>
            </Grid>
            <Grid item xs={10.5} sm={11} md={11.2} lg={11.5} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
              <Typography className="jobTitle">{this.TraslationFuncation("JobDescriptionPage.EditJobDescriptionSubText")}</Typography>
            </Grid>
            <Formik
              data-test-id="EditJobFormik"
              initialValues={{
                jobTitle: EditJobTitle,
                DepartmentTeam: EditDepartmentTeam,
                jobLevel: EditJobLevel,
                JobExperience: EditJobExperience,
                jobFamily: EditJobFamily,
                jobSummary: EditJobSummary,
                keySkills: EditKeySkills,
                keyWords: EditKeyWords,
              }}
              validationSchema={this.EditJobSchema}
              onSubmit={(values) => {
                this.EditJobDescripationCall(values);
              }}
            >
              {({
                handleSubmit,
                values,
                handleChange,
                handleBlur,
                setFieldValue,
              }) => {
                return(
                  <Form
                onSubmit={handleSubmit}
                noValidate
                name="EditJobDetailForm"
                autoComplete="off"
              >
                    <Grid item xs={12} sm={12} md={11.2} lg={11.5} className="jobDetailHeaderGrid">
                      <Wrapper>
                        <Box className="companyItemMainBox">
                          <Grid container className={"companyCardContainer"+" "+this.ConditionForLanguage("","companyCardContainerArabic")} justifyContent={"flex-start"}>
                            <Grid item xs={12} className="companyCardItem">
                              <Card className="companyCardDescBox">
                                <CardContent className="cardContentPadding">
                                  <Grid container>
                                    <Grid item xs={12} className="orgDetailPadding">
                                      <Typography className="JobDetailTitle">
                                        {this.TraslationFuncation("JobDescriptionPage.JobDescription")}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Divider
                                        sx={{
                                          color: "#E2E8F0",
                                          width: 'calc(100% - 35px)',
                                          marginRight: '35px',
                                        }}
                                      />
  
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={3.5} className="contentPadding">
                                    <Grid item xs={12}>
                                      <Wrapper>
                                        <Box className="errorStyleMainbox">
                                          <Typography className="cardInputLabel">
                                            {this.TraslationFuncation("JobDescriptionPage.JobTitle")} <span>*</span>
                                          </Typography>
                                          <ErrorMessage name="jobTitle" component="div" className="errorStyle" />
                                        </Box>
                                        <input
                                          className="dropDownStyle"
                                          placeholder={this.TraslationFuncation("JobDescriptionPage.SpecifyYourTitle")}
                                          data-test-id="EditJobTitle"
                                          name="jobTitle"
                                          onChange={(e)=>{
                                            handleChange(e)
                                            this.setState({
                                              EditJobTitle:e.target.value
                                            })
                                          }}
                                          onBlur={handleBlur}
                                          value={values.jobTitle}
                                        />
                                      </Wrapper>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <Wrapper>
                                        <Box className="errorStyleMainbox">
                                          <Typography className="cardInputLabel">
                                            {this.TraslationFuncation("JobDescriptionPage.DepartmentTeam")}  <span>*</span>
                                          </Typography>
                                          <ErrorMessage name="DepartmentTeam" component="div" className="errorStyle" />
                                        </Box>
                                        <input
                                          className="dropDownStyle"
                                          placeholder={this.TraslationFuncation("JobDescriptionPage.SpecifyYourDepartmentTeam")}
                                          name="DepartmentTeam"
                                          onChange={(e)=>{
                                            handleChange(e)
                                            this.setState({
                                              EditDepartmentTeam:e.target.value
                                            })
                                          }}
                                          data-test-id="EditDepartmentTeam"
                                          onBlur={handleBlur}
                                          value={values.DepartmentTeam}
                                        />
                                      </Wrapper>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <Box className="errorStyleMainbox">
                                        <Typography className="cardInputLabel">
                                          {this.TraslationFuncation("JobDescriptionPage.JobLevel")}  <span>*</span>
                                        </Typography>
                                        <ErrorMessage name="jobLevel" component="div" className="errorStyle" />
                                      </Box>
                                      <Select
                                        data-test-id="EditsetJobLevel"
                                        className={`dropDownStyle`}
                                        inputProps={{
                                          icon: {
                                            left: 0,
                                          },
                                        }}
                                        variant="outlined"
                                        name="jobLevel"
                                        value={values.jobLevel}
                                        onChange={(e)=>{
                                          handleChange(e)
                                          this.setState({
                                            EditJobLevel: Number(e.target.value)
                                          })
                                        }}
                                        onBlur={handleBlur}
                                        displayEmpty
                                        renderValue={(selected) => {
                                          return this.renderjobLevel(selected)
                                        }}
                                        IconComponent={KeyboardArrowRightIcon}
                                        input={<InputBase />}
                                      >
                                        {this.state.jobLevel?.map((option) => (
                                          <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <Wrapper>
                                        <Box className="errorStyleMainbox">
                                          <Typography className="cardInputLabel">
                                            {this.TraslationFuncation("JobDescriptionPage.JobExperience")} <span>*</span>
                                          </Typography>
                                          <ErrorMessage name="JobExperience" component="div" className="errorStyle" />
                                        </Box>
                                        <Select
                                          data-test-id="EditsetJdStyle"
                                          className={`dropDownStyle`}
                                          inputProps={{
                                            icon: {
                                              left: 0,
                                            },
                                          }}
                                          variant="outlined"
                                          name="JobExperience"
                                          value={values.JobExperience}
                                          onChange={(e)=>{
                                            handleChange(e)
                                            this.setState({
                                              EditJobExperience: Number(e.target.value)
                                            })
                                          }}
                                          onBlur={handleBlur}
                                          displayEmpty
                                          IconComponent={KeyboardArrowRightIcon}
                                          input={<InputBase />}
                                        >
                                          {this.state.jdstyle?.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                              {option.name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </Wrapper>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <Wrapper>
                                        <Box className="errorStyleMainbox">
                                          <Typography className="cardInputLabel">
                                            {this.TraslationFuncation("JobDescriptionPage.JobFamily")} <span>*</span>
                                          </Typography>
                                          <ErrorMessage name="jobFamily" component="div" className="errorStyle" />
                                        </Box>
                                        <Select
                                          data-test-id="EditsetJobFamily"
                                          className={`dropDownStyle`}
                                          inputProps={{
                                            icon: {
                                              left: 0,
                                            },
                                          }}
                                          variant="outlined"
                                          name="jobFamily"
                                          value={values.jobFamily}
                                          onChange={(e)=>{
                                            handleChange(e)
                                            this.setState({
                                              EditJobFamily: Number(e.target.value)
                                            })
                                          }}
                                          onBlur={handleBlur}
                                          displayEmpty
                                          IconComponent={KeyboardArrowRightIcon}
                                          input={<InputBase />}
                                        >
                                          {this.state.jobFamily?.map((option) => (
                                            <MenuItem key={option.id} value={`${option.id}`}>
                                              {option.name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </Wrapper>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Wrapper>
                                        <Box className="errorStyleMainbox">
                                          <Typography className="cardInputLabel">
                                            {this.TraslationFuncation("JobDescriptionPage.JobSummary")} <span>*</span>
                                          </Typography>
                                          <ErrorMessage
                                            name="jobSummary"
                                            component="div"
                                            className="errorStyle" />
                                        </Box>
                                        <textarea
                                          className={`cultureInputField`}
                                          placeholder={this.TraslationFuncation("JobDescriptionPage.WriteAboutYourJob")}
                                          data-test-id="EditsetJobSummary"
                                          name="jobSummary"
                                          value={values.jobSummary}
                                          onChange={(e)=>{
                                            handleChange(e)
                                            this.setState({
                                              EditJobSummary: e.target.value
                                            })
                                          }}
                                          onBlur={handleBlur}
                                        />
                                        <Typography className="companyCultureLimit">{this.state.jobSummary.length}/1000</Typography>
                                      </Wrapper>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <Wrapper>
                                        <Box className="errorStyleMainbox">
                                          <Typography className="cardInputLabel">
                                            {this.TraslationFuncation("JobDescriptionPage.KeySkills")}  <span>*</span>
                                          </Typography>
                                          <ErrorMessage name="keySkills"
                                           component="div" 
                                           className="errorStyle" />
                                        </Box>
                                        <Select
                                          className="dropDownStyle"
                                          data-test-id="setkeySkills"
                                          inputProps={{
                                            icon: {
                                              left: 0,
                                            },
                                          }}
                                          value={values.keySkills}
                                          name="keySkills"
                                          variant="outlined"
                                          onChange={(event) => {
                                            const value = event.target.value;
                                            setFieldValue("keySkills", typeof value === 'string' ? value.split(',') : value);
                                          }}
                                          onBlur={handleBlur}
                                          multiple
                                          displayEmpty
                                          renderValue={(selected) =>
                                            this.handleCondition(selected?.length > 0, selected?.map((id: string | number) => {
                                              const skill = this.state.keySkills.find(skill => skill.id === id);
                                              return skill ? skill.name : "";
                                            }).join(" | "), <div className="dropDownStyleplaceHolder">{
                                              this.TraslationFuncation("JobDescriptionPage.SelectYourKeySkills")
                                            }</div>)
                                          }
                                          input={<InputBase />}
                                          IconComponent={KeyboardArrowRightIcon}
                                        >
                                          {this.state.keySkills?.map((option: {id:number, name:string}) => (
                                            <MenuItem key={option.id} value={option.id}>
                                              {option.name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </Wrapper>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <Wrapper>
                                        <Box className="errorStyleMainbox">
                                          <Typography className="cardInputLabel">
                                            {this.TraslationFuncation("JobDescriptionPage.KeyWords")}  <span>*</span>
                                          </Typography>
                                          <ErrorMessage name="keyWords"
                                           component="div" className="errorStyle" />
                                        </Box>
                                        <Select
                                          value={values.keyWords}
                                          data-test-id="setkeyWords"
                                          className="dropDownStyle"
                                          name="keyWords"
                                          variant="outlined"
                                          onChange={(event) => {
                                            const value = event.target.value;
                                            setFieldValue("keyWords", typeof value === 'string' ? value.split(',') : value);
                                          }}
                                          multiple
                                          displayEmpty
                                          onBlur={handleBlur}
                                          renderValue={(selected) => {
                                            if (selected?.length > 0) {
                                              return selected.map((obj: string) => {
                                                const wordObj = this.state.keyWords.find((words) => words.keywords === obj);
                                                return wordObj ? wordObj.keywords : "";
                                              })
                                                .join(" | ");
                                            } else {
                                              return (
                                                <div className="dropDownStyleplaceHolder">
                                                  {this.TraslationFuncation("JobDescriptionPage.SelectYourKeyWords")}
                                                </div>
                                              );
                                            }
                                          }}
                                          IconComponent={KeyboardArrowRightIcon}
                                          input={<InputBase />}
                                        >
                                          {this.state.keyWords?.map((option, index) => (
                                            <MenuItem key={index} 
                                              value={option.keywords}>
                                              {option.keywords}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </Wrapper>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Wrapper>
                                        <Grid container>
                                          <Grid item xs={12}>
                                            <Button className="sumbmitBtn" data-test-id="editJobDescription" onClick={()=>{                                              
                                              handleSubmit()
                                            }}
                                            >
                                              <Typography className="sumbmitText">
                                                {this.TraslationFuncation("JobDescriptionPage.UpdateJobDescription")}
                                              </Typography>
                                            </Button>
                                          </Grid>
                                        </Grid>
                                      </Wrapper>
                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Grid>
                        </Box>
                      </Wrapper>
                    </Grid>
                  </Form>
                )
              }}
            </Formik>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  jobdescriptionDashboard = () => {

    return (
      <MainWrapper>
        <Grid container justifyContent={"center"}>
          <Grid xs={11}>
            <Grid container alignItems={"center"} gap={6}>
              <Grid item xs={12} sm={12} md={11.2} lg={11.5}
                display={"flex"} alignItems={"center"} justifyContent={"space-between"}
                gap={2}
                flexWrap={"wrap"}
              >
                <Grid
                  item
                  xs={12} sm={12} md={6}
                  display={"flex"}
                  gap={2}
                >
                  <CustomDatePicker style={{ flex: 1 }}>
                    <TextField
                      fullWidth
                      value={this.formatDateRange(this.state.selectedDate)}
                      onClick={this.openCalendar}
                      placeholder={this.TraslationFuncation("JobDescriptionPage.SelectStartDateEndDate")}
                      data-testID="calendar-field"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={this.openCalendar}>
                              <img src={calendar.default} onClick={this.openCalendar}/>
                            </IconButton>
                          </InputAdornment>
                        ),
                        inputProps: {
                          style: {
                            textAlign: 'center',
                            color: this.handleCondition(this.state.selectedDate , '#334155' , '#6C7B89'),
                            fontFamily: "Urbanist",
                            fontWeight: this.handleCondition(this.state.selectedDate , 700 , 400),
                            fontSize: "16px",
                            paddingRight: '0px',
                          },
                        },
                        style: {
                          justifyContent: 'center',
                        },
                      }}
                      InputLabelProps={{
                        style: { textAlign: 'center', width: '100%' },
                      }}
                    />
                    {this.state.calendarOpen && (
                      <div className="reactCalendarBox" style={{ 
                        right: this.ConditionForLanguage("","'20px'"),
                        left: this.ConditionForLanguage("-10,",""),
                       }} >
                        <Calendar
                          selectRange
                          onChange={this.handleDateChange}
                          data-testID="calendar"
                          value={this.state.tempDate || this.state.selectedDate}
                        />
                        <div className="action-button-div">
                          <Button
                            className="action-btn cancel-btn"
                            onClick={this.handleCalendarCancelBtn}
                            data-testID="cancel-btn"
                          >
                            {this.TraslationFuncation("Cancel")}
                          </Button>
                          <Button
                            data-testID="save-btn"
                            className={this.handleCondition(this.state.tempDate , `action-btn save-btn active` , `action-btn save-btn`)}
                            onClick={this.handleCalendarSaveBtn}
                          >
                            {this.TraslationFuncation("Apply")}
                          </Button>
                        </div>
                      </div>
                    )}
                  </CustomDatePicker>
                  <Search style={{ flex: 1, border: "1px solid #94A3B8", display: "flex", flexDirection: "row-reverse" }}>
                    <IconButton sx={{marginRight:"10px"}} onClick={this.handleSearchIcon} data-test-id="searchIcon">
                                    <SearchIcon />
                                </IconButton>
                    <StyledInputBase
                      placeholder={this.TraslationFuncation("Search")}
                      fullWidth
                      data-test-id="search_box"
                      inputProps={{ 'aria-label': 'search' }}
                      value={this.state.searchText}
                      onChange={this.handleSearch}
                      onKeyUp={this.handleKeyPress}
                    />
                  </Search>
                </Grid>
                <Grid
                  item
                  xs={12} sm={12} md={4}
                  display={"flex"}
                  gap={2}
                  style={{ width: '100%' }}
                >
                  <Button
                    data-test-id="ExportAllButton"
                    className="editButton"
                    variant="outlined"
                    sx={{
                      backgroundColor: "#FFFFFF",
                      borderColor: "#94A3B8",
                      width: { xs: '100%', sm: '100%' },
                      height: "58px",
                      padding: "10px 16px",
                      borderRadius: "4px",
                      textTransform: "none",
                      
                      "&:hover": {
                        backgroundColor: "#FFFFFF",
                      },
                      "& .MuiTypography-root": {
                        fontFamily: "Urbanist",
                        fontSize: "16px",
                        fontWeight: 500,
                        lineHeight: "28px",
                        color: "#044352",
                      }
                    }}
                    onClick={this.ExportAllApiCall}
                  >
                    <Typography className="editButtonText">{this.TraslationFuncation("ExportAll")}</Typography>
                  </Button>
                  <Button
                    data-test-id="createJobdesc"
                    className="editButton"
                    variant="outlined"
                    sx={{
                      backgroundColor: "#044352",
                      width: { xs: '100%', sm: '100%' },
                      height: "58px",
                      padding: "10px 30px",
                      borderRadius: "4px",
                      textTransform: "none",
                      whiteSpace: this.ConditionForLanguage("unset","nowrap"),
                      "@media(max-width:1050px)": {
                        padding: "10px 10px"
                    },
                      "&:hover": {
                        backgroundColor: "#044352",
                      },
                      "& .MuiTypography-root": {
                        fontFamily: "Urbanist",
                        fontSize: "16px",
                        fontWeight: 500,
                        lineHeight: "28px",
                        color: "#FFFFFF",
                      }
                    }}
                    onClick={this.createNewJob}
                  >
                    <Typography className="editButtonText" style={{ fontWeight: 700 }}>{this.TraslationFuncation("JobDescriptionPage.CreateNewJob")}</Typography>
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={11.2} lg={11.5}>
                {this.state.SearchResult  ? <>
                 <TableContainer sx={{ mt: 2 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="tableDataText">{this.TraslationFuncation("JobDescriptionPage.JobTitle")}</TableCell>
                        <TableCell className="tableDataText">{this.TraslationFuncation("JobDescriptionPage.JobID")}</TableCell>
                        <TableCell className="tableDataText">{this.TraslationFuncation("JobDescriptionPage.Department")}</TableCell>
                        <TableCell className="tableDataText">{this.TraslationFuncation("JobDescriptionPage.JobExperience")}</TableCell>
                        <TableCell className="tableDataText">{this.TraslationFuncation("JobDescriptionPage.JOBfamily")}</TableCell>
                        <TableCell className="tableDataText">{this.TraslationFuncation("JobDescriptionPage.CreatedOn")}</TableCell>
                        <TableCell className="tableDataText">{this.TraslationFuncation("JobDescriptionPage.Status")}</TableCell>
                        <TableCell className="tableDataText">{this.TraslationFuncation("JobDescriptionPage.Action")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.userdetailsData.length !== 0&& !this.state.loader ? this.state.userdetailsData.map((row: {
                        JobTitle: string;
                        JOBfamily: string;
                        id: string;
                        JobID: string;
                        CreatedOn: string;
                        JobExperience: string;
                        Department: string;
                        AllResponse: {
                          attributes: {
                            job_family: { id: number; name:string},
                            job_experience: { id: number; name:string },
                            job_level: { id: number; name:string },
                            job_title: string,
                            key_words:Array<string>,
                            job_summary: string,
                            department:string,
                            key_skills: {
                              data: Array<{ attributes: { id: number; skill_name: string; } }>
                            }
                          }
                        }
                        chatgptResponse: {
                          job_level: string; job_experience: string; job_summary: string; key_skills: string[]; keywords: string[];
                          key_responsibilities: string[]
                        }
                      }, index: number) => (
                        <TableRow key={index}>
                          <TableCell className="tableDataText">{row.JobTitle}</TableCell>
                          <TableCell className="tableDataText">{row.JobID}</TableCell>
                          <TableCell className="tableDataText">{row.Department}</TableCell>
                          <TableCell className="tableDataText">{row.JobExperience}</TableCell>
                          <TableCell className="tableDataText">{row.JOBfamily}</TableCell>
                          <TableCell className="tableDataText">{row.CreatedOn}</TableCell>
                          <TableCell className="tableDataText">
                            <Select
                              displayEmpty
                              variant="outlined"
                              data-test-id="user_status1"
                              className="statusRow"
                              value={this.state.selectedStatus[index] || ''}
                              renderValue={
                                this.handleCondition(
                                  this.state.selectedStatus[index]
                                  , undefined
                                  , () => <div className="statusDropDown">{this.TraslationFuncation("JobDescriptionPage.Active")}</div>
                                )
                              }
                              IconComponent={KeyboardArrowDownIcon}
                              onChange={(e) => this.handleStatus(e, index)}
                              input={<InputBase />}
                            >
                              {this.state.userStatus?.map((status) => (
                                <MenuItem key={status} value={status}
                                  sx={{
                                    background: `${status === "Active" ? "#f1f5f9" : "none"}`,
                                    fontFamily: "Urbanist",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    margin: "4px"
                                  }}>
                                  {this.TraslationFuncation(`JobDescriptionPage.${status}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          </TableCell>
                          <TableCell>
                            <ClickAwayListener onClickAway={() => this.setState({ openedMenu: null })}>
                              <div style={{ position: 'relative' }}>
                                <IconButton data-test-id="dotsData"
                                  onClick={() => {
                                    this.handleClickOpen(index, row.id);
                                  }}>
                                  <MoreVertIcon />
                                </IconButton>
                                {this.state.openedMenu === index && (
                                  <div style={{
                                    position: 'absolute',
                                    backgroundColor: 'white',
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                                    zIndex: 1000,
                                    width:"150px",
                                    right: this.ConditionForLanguage("20px",""),
                                    left: this.ConditionForLanguage("","20px"),
                                  }}>
                                    {this.state.userdetails?.map((details, index) => (
                                      <div
                                        key={details}
                                        data-test-id={`selectId-${index}`}
                                        onClick={() => this.handleSelect(details, row.id, row)}
                                        style={{
                                          color: this.handleCondition(details === "Delete details", "#F87171", "#0F172A"),
                                          fontFamily: "Urbanist",
                                          fontSize: "14px",
                                          fontWeight: 400,
                                          padding: '15px 30px 15px 30px',
                                          cursor: 'pointer',
                                          backgroundColor: this.handleCondition(this.state.selectedDetails === details, '#E2E8F0', 'transparent'),
                                        }}
                                      >
                                         {this.TraslationFuncation(`JobDescriptionPage.${details.replace(/\s+/g, '')}`)}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </ClickAwayListener>

                          </TableCell>
                        </TableRow>
                      )): this.state.loader && this.loaderScreen() }
                    </TableBody>
                  </Table>
                </TableContainer>

                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mr: 8 }}>
                  <Pagination
                    color="primary"
                    boundaryCount={1}
                    siblingCount={0}
                    page={this.state.PaginationPageCount}
                    style={{
                       direction:"ltr"
                    }}
                    data-test-id="pagination"
                    onChange={this.PaginationFuncation}
                    count={Math.ceil(this.state.TotalRecord / this.state.PerPageDataRender)}
                  />
                </Box> 
                  </>: 
                  this.emptyDataScreen()
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MainWrapper>
    )
  }

  createCompanyDialog = () => {
    return (
        <Dialog
            open={this.state.opencompanyDialog}
            onClose={this.handleCloseCompanyDialog}
        >
            <DialogTitle>
                <DialogWrapper>
                    <Typography className="companyDialogTitle">
                        {this.TraslationFuncation("JobDescriptionPage.CreateCompany")}
                    </Typography>
                    <IconButton
                        aria-label="close"
                        data-test-id="close_create_company"
                        onClick={this.handleCloseCompanyDialog}
                        className="companyDialogClose"
                        sx={{
                            right: this.state.selectedLanguage.toLowerCase() === "english" ? 30 : "auto",
                            left: this.state.selectedLanguage.toLowerCase() === "english" ? "auto" : 30
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogWrapper>
            </DialogTitle>
            <Divider sx={{ color: "#E2E8F0" }} />
            <DialogContent>
                <Typography
                    sx={{
                        fontSize: "20px",
                        fontWeight: 700,
                        fontFamily: "Urbanist",
                        padding: "14px 34px",
                        lineHeight: "28px",
                        color: "#0F172A",
                        textAlign: "center"
                    }}>
                    {this.TraslationFuncation("JobDescriptionPage.CreateCompanySubText")}
                </Typography>

            </DialogContent>
            <Divider />
            <DialogActions>
                <DialogWrapper>
                    <Box className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "companyButtonsBox" : "companyButtonsBoxArabic"}`}>
                        <Button
                            color="primary"
                            variant="contained"
                            data-test-id="navigate_to_create_company"
                            onClick={this.navigateToCreateCompany}
                            className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "createCompanyButton" : "createCompanyButtonArabic"}`}
                        >
                            {this.TraslationFuncation("JobDescriptionPage.CreateCompany")}
                        </Button>
                    </Box>
                </DialogWrapper>
            </DialogActions>
        </Dialog>
    )
}
renderjobLevel = (selected: number) => {
  const selectedjobLevel = this.state.jobLevel?.find((option) => option.id === selected);
  return selectedjobLevel ? selectedjobLevel.name : (
    <div className="dropDownStyleplaceHolder"
    >  {this.TraslationFuncation("JobDescriptionPage.SpecifyYourTitle")}
    </div>
  );
}

renderjd = (selected: number) => {
  const selectedjdStyle = this.state.jdstyle?.
  find((option) =>option.id === selected);
  return selectedjdStyle ? selectedjdStyle.name : 
  (<div className="dropDownStyleplaceHolder">
    {this.TraslationFuncation("JobDescriptionPage.SelectYourJobExperience")}</div>
  )
}

  RenderFirstCondition = () => {
    return (
      <>
        {this.state.loader === true && this.loaderScreen()}
        {this.state.emptyscreen && !this.state.loader && this.emptyDataScreen()}
        {this.state.userdetailsData?.length > 0 && this.state.JobDescdashboard && !this.state.loader && this.jobdescriptionDashboard()}
        {!this.state.companyName && this.createCompanyDialog()}
      </>
    )
  }

  CondtionForKeyWords =(selected:Array<string>) =>{
    if (selected?.length > 0) {
      return selected.map((obj:string) => {
          const wordObj = this.state.keyWords.find((words) => words.keywords === obj);
          return wordObj ? wordObj.keywords : "";
        })
        .join(" | ");
    } else {
      return (
        <div className="dropDownStyleplaceHolder">
          {this.TraslationFuncation("JobDescriptionPage.SelectYourKeyWords")}
        </div>
      );
    }
  }

renderjobfamily = (selected:number) => {
  const selectedfamily = this.state.jobFamily?.
    find((option) => option.id === selected);
    return selectedfamily ? selectedfamily.name : (
      <div 
      className="dropDownStyleplaceHolder">
        {this.TraslationFuncation("JobDescriptionPage.SpecifyYourJobFamily")}
      </div>
    );
}
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <DashboardHeader selectedMenu="Job descriptions" navigation={this.props.navigation} id={this.props.id}> 
      <div style={{ direction: this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl" }} >      
      {this.RenderFirstCondition()}

        <Formik
            data-test-id="formik"
            initialValues={{
              jobTitle: '',
              DepartmentTeam: '',
              jobLevel: '',
              jdStyle: '',
              jobFamily:'',
              jobSummary: '',
              keySkills: [],
              keyWords: []
            }}
            validationSchema={this.jobDescValidationSchema}
            onSubmit={(values, { resetForm }) => {
              this.setState({
                progressScreen:true,
                formscreen:false,
              })
              this.jobDescriptionCall(values)
              resetForm();
            }}
          >
            {({
              handleSubmit,
              values,
              handleChange,
              handleBlur,
              setFieldValue
            }) => (
              <Form
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
              >
              <MainWrapper className="FormModel" >
                {this.handleTrueCondition(this.state.formscreen
                ,
                <Grid container justifyContent={"center"}>
                {this.handleTrueCondition(!this.state.descriptionLoader , (
                <Grid item xs={11}>
                    <Grid container spacing={1} alignItems={"center"} style={{ direction:"ltr" }}>
                        <Grid item xs={1.5} sm={1} md={0.8} lg={0.5} >
                          <Box data-test-id="Dashboard" onClick={this.navigateToDashboard}
                          style={{width:"47px" , height:"47px", border:"1px solid #ECECEC" , borderRadius:"50%", display: "flex", alignItems: "center", justifyContent: "center",  }}
                          >
                            <img src={arrow_Left.default}
                                alt="Back Arrow"  
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={10.5} sm={11} md={11.2} lg={11.5}>
                            <Typography className="jobTitle">{this.TraslationFuncation("JobDescriptionPage.JobDescription")}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} className={"jobMainHeaderGrid"+" arbic "+this.ConditionForLanguage("","JobMainHeaderGridArabic")} style={{   }} >
                        <Grid item xs={12} sm={12} md={11.2} lg={11.5}>
                          <Wrapper>
                              <Box className="companyItemMainBox">
                                  <Grid container className={"companyCardContainer"+" "+this.ConditionForLanguage("","companyCardContainerArabic")} justifyContent={"flex-start"}>
                                      <Grid item xs={12} className="companyCardItem">
                                          <Card className="companyCardDescBox">
                                              <CardContent className="cardContentPadding">
                                                  <Grid container>
                                                      <Grid item xs={12} className="orgDetailPadding">
                                                          <Typography className="cardTitle">
                                                            {this.TraslationFuncation("JobDescriptionPage.JobDescription")}
                                                          </Typography>
                                                      </Grid>
                                                      <Grid item xs={12}>
                                                          <Divider />
                                                      </Grid>
                                                  </Grid>
                                                  <Grid container spacing={3.5} className="contentPadding">
                                                      <Grid item xs={12}>
                                                      <Wrapper>
                                                          <Box className="errorStyleMainbox">
                                                              <Typography className="cardInputLabel">
                                                                {this.TraslationFuncation("JobDescriptionPage.JobTitle")} <span>*</span>
                                                              </Typography>
                                                              <ErrorMessage name="jobTitle" component="div" className="errorStyle" />
                                                          </Box>
                                                          <input
                                                            className="dropDownStyle"
                                                            placeholder={this.TraslationFuncation("JobDescriptionPage.SpecifyYourTitle")}
                                                            name="jobTitle"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.jobTitle}
                                                          />
                                                      </Wrapper>
                                                      </Grid>
                                                      <Grid item xs={12} md={6}>
                                                      <Wrapper>
                                                          <Box className="errorStyleMainbox">
                                                              <Typography className="cardInputLabel">
                                                                {this.TraslationFuncation("JobDescriptionPage.DepartmentTeam")}  <span>*</span>
                                                              </Typography>
                                            <ErrorMessage
                                              name="DepartmentTeam"
                                              component="div" className="errorStyle" />
                                                          </Box>
                                                          <input
                                                              className="dropDownStyle"
                                                              placeholder={this.TraslationFuncation("JobDescriptionPage.SpecifyYourDepartmentTeam")}
                                                              name="DepartmentTeam"
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              value={values.DepartmentTeam}
                                                            />
                                                      </Wrapper>
                                                      </Grid>
                                                      <Grid item xs={12} md={6}>
                                                      <Wrapper>
                                                        <Box className="errorStyleMainbox">
                                                            <Typography className="cardInputLabel">
                                                               {this.TraslationFuncation("JobDescriptionPage.JobLevel")}  <span>*</span>
                                                            </Typography>
                                                            <ErrorMessage name="jobLevel" 
                                                            component="div" className="errorStyle" />
                                                        </Box>
                                                        <Select
                                                        data-test-id="setJobLevel"
                                                        className={`dropDownStyle`}
                                                        inputProps={{
                                                            icon: {
                                                                left: 0,
                                                            },
                                                        }}
                                                        variant="outlined"
                                                        name="jobLevel"
                                                        value={values.jobLevel}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        displayEmpty
                                                        renderValue={(selected) => {
                                                        return this.renderjobLevel(Number(selected))
                                                        }}
                                                        IconComponent={KeyboardArrowRightIcon}
                                                        input={<InputBase />}
                                                    >
                                                        {this.state.jobLevel?.map((option) => (
                                                            <MenuItem key={option.id} value={option.id}>
                                                                {option.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    </Wrapper>
                                                      </Grid>
                                                      <Grid item xs={12} md={6}>
                                                      <Wrapper>
                                                        <Box className="errorStyleMainbox">
                                                            <Typography className="cardInputLabel">
                                                              {this.TraslationFuncation("JobDescriptionPage.JobExperience")} <span>*</span>
                                                            </Typography>
                                                            <ErrorMessage name="jdStyle" component="div" className="errorStyle" />
                                                        </Box>
                                                        <Select
                                                        data-test-id="setJdStyle"
                                                        className={`dropDownStyle`}
                                                        inputProps={{
                                                            icon: {
                                                                left: 0,
                                                            },
                                                        }}
                                                        variant="outlined"
                                                        name="jdStyle"
                                                        value={values.jdStyle}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        displayEmpty
                                                        renderValue={(selected) => 
                                                          this.renderjd(Number(selected))}
                                                        IconComponent={KeyboardArrowRightIcon}
                                                        input={<InputBase />}
                                                    >
                                                        {this.state.jdstyle?.map((option) => (
                                                            <MenuItem key={option.id} value={option.id}>
                                                                {option.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    </Wrapper>
                                                      </Grid>
                                                      <Grid item xs={12} md={6}>
                                                      <Wrapper>
                                                        <Box className="errorStyleMainbox">
                                                            <Typography className="cardInputLabel">
                                                               {this.TraslationFuncation("JobDescriptionPage.JobFamily")} <span>*</span>
                                                            </Typography>
                                                            <ErrorMessage name="jobFamily" component="div" className="errorStyle" />
                                                        </Box>
                                                        <Select
                                                        data-test-id="setJobFamily"
                                                        className={`dropDownStyle`}
                                                        inputProps={{
                                                            icon: {
                                                                left: 0,
                                                            },
                                                        }}
                                                        variant="outlined"
                                                        name="jobFamily"
                                                        value={values.jobFamily}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        displayEmpty
                                                        renderValue={
                                                          (selected) => this.renderjobfamily(Number(selected))}
                                                        IconComponent={KeyboardArrowRightIcon}
                                                        input={<InputBase />}
                                                    >
                                                        {this.state.jobFamily?.map((option) => (
                                                            <MenuItem key={option.id} value={option.id}>
                                                                {option.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    </Wrapper>
                                                      </Grid>
                                                      <Grid item xs={12}>
                                                      <Wrapper>
                                                          <Box className="errorStyleMainbox">
                                                              <Typography className="cardInputLabel">
                                                                {this.TraslationFuncation("JobDescriptionPage.JobSummary")} <span>*</span>
                                                              </Typography>
                                                              <ErrorMessage name="jobSummary" component="div" className="errorStyle" />
                                                          </Box>
                                                          <textarea
                                                              className={`cultureInputField`}
                                                              placeholder={this.TraslationFuncation("JobDescriptionPage.WriteAboutYourJob")}
                                                              name="jobSummary"
                                                              value={values.jobSummary}
                                                              onBlur={handleBlur}
                                                              onChange={handleChange}
                                                          />
                                                          <Typography className="companyCultureLimit">{this.state.jobSummary.length}/1000</Typography>
                                                      </Wrapper>
                                                      </Grid>
                                                      <Grid item xs={12} md={6}>
                                                      <Wrapper>
                                                        <Box className="errorStyleMainbox">
                                                            <Typography className="cardInputLabel">
                                                            {this.TraslationFuncation("JobDescriptionPage.KeySkills")}  <span>*</span>
                                                            </Typography>
                                                            <ErrorMessage name="keySkills" component="div" className="errorStyle" />
                                                        </Box>
                                                        <Select
                                                          data-test-id="setkeySkills"
                                                          className="dropDownStyle"
                                                          inputProps={{
                                                          icon: {
                                                            left: 0,
                                                          },
                                                          }}
                                                          variant="outlined"
                                                          name="keySkills"
                                                          value={values.keySkills}
                                                          onChange={(event) => {
                                                          const value = event.target.value;
                                                          setFieldValue("keySkills", typeof value === 'string' ? value.split(',') : value);
                                                          }}
                                                          onBlur={handleBlur}
                                                          multiple
                                                          displayEmpty
                                                          renderValue={(selected) => 
                                                            this.handleCondition(selected?.length > 0 , selected?.map((id:number) => {
                                                              const skill = this.state.keySkills.find(skill => skill.id === id);
                                                              return skill ? skill.name : "";
                                                            }).join(" | ") , <div className="dropDownStyleplaceHolder"> 
                                                            {this.TraslationFuncation("JobDescriptionPage.SelectYourKeySkills")}
                                                            </div>)
                                                          }
                                                          IconComponent={KeyboardArrowRightIcon}
                                                          input={<InputBase />}
                                                          >
                                                          {this.state.keySkills?.map((option) => (
                                                          <MenuItem key={option.id} value={option.id}>
                                                            {option.name}
                                                          </MenuItem>
                                                          ))}
                                                          </Select>
                                                    </Wrapper>
                                                      </Grid>
                                                      <Grid item xs={12} md={6}>
                                                      <Wrapper>
                                                        <Box className="errorStyleMainbox">
                                                            <Typography className="cardInputLabel">
                                                              {this.TraslationFuncation("JobDescriptionPage.KeyWords")}  <span>*</span>
                                                            </Typography>
                                                            <ErrorMessage name="keyWords" component="div" className="errorStyle" />
                                                        </Box>
                                                        <Select
                                                            data-test-id="setkeyWords"
                                                            className="dropDownStyle"
                                                            name="keyWords"
                                                            variant="outlined"
                                                            value={values.keyWords}
                                                            onChange={(event) => {
                                                              const value = event.target.value;
                                                              setFieldValue("keyWords", typeof value === 'string' ? value.split(',') : value);
                                                            }}
                                                            onBlur={handleBlur}
                                                            displayEmpty
                                                            multiple
                                                            renderValue={(selected) => {
                                                              return this.CondtionForKeyWords(selected)
                                                            }}
                                                            input={<InputBase />}
                                                            IconComponent={KeyboardArrowRightIcon}
                                                          >
                                                            {this.state.keyWords?.map((option, index) => (
                                                              <MenuItem key={index} value={option.keywords}>
                                                                {option.keywords}
                                                              </MenuItem>
                                                            ))}
                                                          </Select>
                                                    </Wrapper>
                                                      </Grid>
                                                      <Grid item xs={12}>
                                                        <Wrapper>
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <Button className="sumbmitBtn" data-test-id="submit_company_details" type="submit">
                                                                        <Typography className="sumbmitText"> 
                                                                          {this.TraslationFuncation("JobDescriptionPage.GenerateJobDescription")}</Typography>
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </Wrapper>
                                                      </Grid>
                                                  </Grid>
                                              </CardContent>
                                          </Card>
                                      </Grid>
                                  </Grid>
                              </Box>
                                 
                          </Wrapper>
                        </Grid>
                    </Grid>
                </Grid>
                ))}
                </Grid>
                )}

                {this.handleTrueCondition(this.state.progressScreen,this.progressDataScreen())}
                {this.handleTrueCondition(this.state.jobDetailScreen,this.jobDetailDataScreen())}
                {this.handleTrueCondition(this.state.editJobDetailScreen,this.editJobDetailDataScreen())}
            </MainWrapper>
          </Form>
            )}
      </Formik>
      <Modal      
          open={this.state.progressOpen}
          onClose={this.closeProgressModal}>
          <CustomPopup 
          data-test-id="custom-popup1"
          headingPopup={this.TraslationFuncation("JobDescriptionPage.JobDescriptionCreated")} 
          titlePopup={this.state.jobdescResponse} 
          subTitlePopup = {this.TraslationFuncation("JobDescriptionPage.JobDescriptionHasSavedInTheSystem")}
          buttonText={this.TraslationFuncation("View")}
          showCancelButton={false}
          successIcon = {right.default}
          onOpen={this.openProgressModal}
          onClose={this.closeProgressModal}/>
          
      </Modal>
      <Modal      
          open={this.state.deleteJobDetail}
          onClose={this.closeJobDeletePopupModal}>
          <CustomPopup 
          data-test-id="JobDeletePopupModal"
          headingPopup={this.TraslationFuncation("JobDescriptionPage.Deletedetails")} 
          titlePopup={this.TraslationFuncation("JobDescriptionPage.SureDeleteJobDec")} 
          subTitlePopup = {this.TraslationFuncation("JobDescriptionPage.YouWontBeAbleToAccessItAgain")}
          cancelButtonText = {this.TraslationFuncation("Cancel")}
          buttonText={this.TraslationFuncation("Delete")}
          successIcon = {deleteIcon.default}
          showCancelButton={true}
          onOpen={this.openJobDeletePopupModal}
          onClose={this.closeJobDeletePopupModal}/>
      </Modal>
      </div>
      </DashboardHeader>
      // Customizable Area End
    );
  }
}

export const Cfchatgptintegration9Web = withTranslation()(Cfchatgptintegration9)

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  padding: "10px 16px",
  color: 'inherit',
  '& .MuiInputBase-input': {
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  width: '100%',
}));

export const DialogWrapper = styled(Box)({
  width: "100%",
  "& .usersButton": {
      fontSize: "18px",
      fontWeight: 700,
      lineHeight: "26.46px",
      color: "#64748B",
      textTransform: "none",
      padding: "8px",
      paddingLeft: 0,
      "&:hover": {
          background: "none"
      }
  },
  "& .companyDialogClose": {
      color: "#334155",
      position: 'absolute',
      top: 16
  },
  "& .exportImageBox": {
      padding: "10px 0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column"
  },
  "& .tableProfilePic": {
      width: "30px",
      height: "30px"
  },
  "& .companyDialogTitle": {
      lineHeight: "32px",
      color: "#1E1E1E",
      margin: "8px 16px",
      fontSize: "24px",
      fontFamily: "Urbanist",
      fontWeight: 700,
  },
  "& .companyButtonsBox": {
      paddingRight: "16px",
      margin: "16px 0px",
      display: "flex",
      justifyContent: "flex-end"
  },
  "& .companyButtonsBoxArabic": {
      paddingLeft: "16px",
      margin: "16px 0px",
      display: "flex",
      justifyContent: "flex-end"
  },
  "& .createCompanyButtonArabic": {
      opacity: "0px",
      background: "#044352",
      height: "56px",
      gap: "8px",
      borderRadius: "8px",
      fontSize: "16px",
      fontWeight: 700,
      fontFamily: "Urbanist",
      padding: "16px 39.5px 16px 36.5px",
      marginRight: "16px",
      textTransform: "none",
      "&:hover": {
          backgroundColor: "#044352"
      },
  },
  "& .createCompanyButton": {
      fontWeight: 700,
      height: "56px",
      fontFamily: "Urbanist",
      fontSize: "16px",
      padding: "16px 24px",
      gap: "8px",
      borderRadius: "8px",
      opacity: "0px",
      marginLeft: "16px",
      background: "#044352",
      textTransform: "none",
      "&:hover": {
          backgroundColor: "#044352"
      },
  },
  "& .profilePicBox": {
      height: "56px",
      width: "56px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: '50%',
      border: "1px solid #F0E5FF",
      background: "#CBD5E1"
  },
  "& .selectedUsersButton": {
      paddingLeft: 0,
      fontSize: "18px",
      fontWeight: 700,
      lineHeight: "26.46px",
      color: "#64748B",
      textTransform: "none",
      padding: "8px",
      borderRadius: "4px",
      border: "1px solid #64748B",
      background: "#F1F5F9",
  },
  "& .searchBox": {
      width: "100%",
      height: "56px",
      borderRadius: "4px",
      fontFamily: "Urbanist",
      border: "1px solid #94A3B8",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      color: "#6C7B89",
      "&:hover": {
          borderColor: "none"
      },
  },
  "& .MuiSelect-icon": {
      transform: 'none !important',
  },
  "& .css-1uwzc1h-MuiSelect-select-MuiInputBase-input": {
      paddingRight: "8px !important"
  },
  "& .statusDropDown": {
      fontFamily: "Urbanist",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "18px",
      color: "#64748B",
      marginTop: "3px"
  },
  "& .statusRow": {
      width: "100px",
      height: "30px",
      borderRadius: "4px",
      padding: "0px 20px 0px 16px",
      textTransform: "none",
      border: "1px solid #CBD5E1",
      color: "#64748B",
      fontFamily: "Urbanist",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "18px",
      marginTop: "3px"
  },
  "& .filterBox": {
      width: "100%",
      height: "55px",
      borderRadius: "4px",
      padding: "0px 20px 0px 16px",
      textTransform: "none",
      background: "#F1F5F9",
      border: "2px solid #94A3B8",
      color: "#64748B"
  },
  "& .filterText": {
      fontFamily: "Urbanist",
      fontWeight: 700,
      fontSize: "16px",
      color: "#64748B"
  },
  "& .exportAllButton": {
      width: "100%",
      height: "58px",
      borderRadius: "4px",
      border: "2px solid #94A3B8",
      "&:hover": {
          background: "none"
      }
  },
  "& .exportAllText": {
      fontFamily: "Urbanist",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      textTransform: "none",
      color: "#64748B"
  },
  "& .tableTilteText": {
      fontFamily: "Urbanist",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      color: "#044352"
  },
  "& .tableDataText": {
      fontFamily: "Urbanist",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "24px",
      color: "#044352"
  }
})

export const MainWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  marginTop: "42px",
  paddingBottom: "30px",
  "& .companyDialogTitle": {
    lineHeight: "32px",
    color: "#1E1E1E",
    margin: "8px 16px",
    fontSize: "24px",
    fontFamily: "Urbanist",
    fontWeight: 700,
},
"& .emptyText": {
    color: "#000104",
    fontFamily: "Urbanist",
    fontSize: "30px",
    lineHeight: "40px",
    fontWeight: 700,
    width: "100%",
    textAlign: "center",
  },
  "& .emptyScreenMessage": {
    color: "#8A96A8",
    fontFamily: "Urbanist",
    fontSize: "1rem",
    lineHeight: "1.5rem",
    fontWeight: 400,
    width: "100%",
    textAlign: "center",
    "@media (max-width: 768px)": {
      fontSize: "0.875rem",
    },
    "@media (max-width: 620px)": {
      fontSize: "0.75rem",
    },
  },
  "& .progressImage": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "100%", 
    height: "auto", 
    "@media (max-width: 768px)": {
      paddingBottom: "20px",
    },
  },
"& .loader-outer": {
  height: "100%",
  minHeight: "100vh",
  position: "absolute",
  left: 0,
  bottom: 0,
  top: 0,
  right: 0,
  zIndex: 1,
},
"& .loader-inner": {
  transform: "translate(-50%, -50%)",
  position: "absolute",
  top: "40%",
  left: "50%",
},
"& .companyDialogClose": {
  color: "#334155",
  position: 'absolute',
  top: 16
},
"& .companyButtonsBox": {
  paddingRight: "16px",
  margin: "16px 0px",
  display: "flex",
  justifyContent: "flex-end"
},
"& .companyButtonsBoxArabic": {
  paddingLeft: "16px",
  margin: "16px 0px",
  display: "flex",
  justifyContent: "flex-end"
},
"& .createCompanyButton": {
  fontWeight: 700,
  height: "56px",
  fontFamily: "Urbanist",
  fontSize: "16px",
  padding: "16px 24px",
  gap: "8px",
  borderRadius: "8px",
  opacity: "0px",
  marginLeft: "16px",
  background: "#044352",
  textTransform: "none",
  "&:hover": {
      backgroundColor: "#044352"
  },
},
"& .createCompanyButtonArabic": {
  opacity: "0px",
  background: "#044352",
  height: "56px",
  gap: "8px",
  borderRadius: "8px",
  fontSize: "16px",
  fontWeight: 700,
  fontFamily: "Urbanist",
  padding: "16px 39.5px 16px 36.5px",
  marginRight: "16px",
  textTransform: "none",
  "&:hover": {
      backgroundColor: "#044352"
  },
},
  "& .tableDataText": {
    fontFamily: "Urbanist",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#044352"
},
"& .statusDropDown": {
  fontFamily: "Urbanist",
  fontSize: "12px",
  fontWeight: 700,
  lineHeight: "18px",
  color: "#64748B",
  marginTop: "3px",
},
"& .statusRow": {
  width: "100px",
  height: "30px",
  borderRadius: "4px",
  paddingLeft: "10px",
  textTransform: "none",
  border: "1px solid #CBD5E1",
  color: "#64748B",
  fontFamily: "Urbanist",
  fontSize: "12px",
  fontWeight: 700,
  lineHeight: "18px",
},
  "& .jobTitle": {
      fontFamily: "Urbanist",
      fontSize: "25px",
      fontWeight: 600,
      lineHeight: "30px",
      color: "#000104",
      marginLeft: "12px"
  },
  "& .jobMainHeaderGrid": {
      marginTop: "30px"
  },
  "& .JobMainHeaderGridArabic": {
      marginTop: "30px",
      justifyContent: "flex-start",
      flexDirection: "row-reverse"
  },
  "& .jobDetailHeaderGrid": {
    marginTop: "45px"
},
"& .headerjobdashboard":{
  display:"flex"
},
"& .header": {
  color:"#044352",
  fontFamily: "Urbanist",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
  padding:"20px"
}
});

const CustomDatePicker = styled(Box)({
  borderRadius: '8px',
  position: 'relative' as 'relative',
  boxSizing: "border-box",
  "& .MuiInput-underline::after, .MuiInput-underline::before": {
    display: 'none'
  },
  "& .reactCalendarBox":{
    marginTop:"5px",
    boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
    position: 'absolute',
    top: '100%',
    zIndex: 1000,
  },
  "& .react-calendar": {
    border: "none",
    fontFamily: "Urbanist",
  },
  "& .react-calendar__navigation button.react-calendar__navigation__label": {
    fontSize: '14px',
    fontFamily: "Urbanist",
    fontWeight: 700,
    color: "#0F172A",
  },
  "& .react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button":{
    display: "none",
  },
  "& .react-calendar__navigation button": {
    fontSize: '14px',
    fontFamily: "Urbanist",
    fontWeight: 700,
    color: "#94A3B8", 
  },
  "& .react-calendar__tile.react-calendar__tile--now": {
    background: 'none',
    color: '#0F172A',
  },
  "& .react-calendar__month-view__weekdays__weekday": {
    textTransform: 'capitalize',
    fontSize: '14px',
    color: '#64748B',
    fontWeight: 400,
    textDecoration: 'none',
  },
  "& .react-calendar__month-view__weekdays__weekday abbr": {
    textDecoration: 'none',
  },
  "& .react-calendar__tile": {
    fontSize: '14px',
    fontWeight: 400,
    color: '#0F172A',
    width: '50px',
    height: '50px',
  },
  "& .react-calendar__tile.react-calendar__tile--range": {
    background: '#F1F5F9',
    color: '#044352',
    borderRadius: '0px',
  },
  "& .react-calendar__tile.react-calendar__tile--rangeStart": {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
    background: '#F1F5F9', 
    color: '#044352',
  },
  "& .react-calendar__tile.react-calendar__tile--rangeEnd": {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
    background: '#F1F5F9',
    color: '#044352',
  },
  "& .react-calendar__tile.react-calendar__tile--active, .react-calendar__tile.react-calendar button:enabled": {
    background: '#F1F5F9',
    color: '#044352',
    fontSize: '14px',
    fontWeight: 700,
  },
  "& .react-calendar__tile.react-calendar__tile--active:hover, .react-calendar__tile.react-calendar button:enabled:hover": {
    background: '#F1F5F9',
    color: '#044352',
    pointerEvents: "none",
},
  "& .react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth": {
    color: '#94A3B8'
  },
  "& .action-button-div": {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 15px',
    gap: '20px',
    background: '#FFFFFF',
  },
  "& .action-button-div button": {
    width: '140px',
    height: '50px',
    borderRadius: '7.21px',
    fontFamily: "Urbanist",
    textTransform: 'capitalize',
    fontSize: '14.42px',
  },
  "& .action-button-div .cancel-btn": {
    background: '#F1F5F9',
    color:"#044352",
    boxShadow: "0px 3.6px 3.6px 0px #00000040"
  },
  "& .action-button-div .save-btn.active": {
    background: '#044352',
    color: '#FFFFFF'
  },
  "& .action-button-div .save-btn": {
    background: '#044352',
    color: '#FFFFFF'
  },
});

export const Wrapper = styled(Box)({
  width: "100%",
  "& .companyCardDescBox": {
      boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px",
      width: "55vw",
      margin: "4vh 3vw",
      "@media(max-width:900px)": {
          width: "80vw"
      },
  },
  "& .jobdetailBox":{
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px",
      width: "62.5vw",
      margin: "4vh 3vw",
      "@media(max-width:900px)": {
          width: "80vw"
      },
  },
  "& .companyItemMainBox": {
      width: "100%",
      overflow: "hidden",
      display: "flex",
      justifyContent: "center",
      position: "relative"
  },
  "& .companyCardItem": {
      flex: "0 0 auto",
      scrollSnapAlign: "center",
  },
  "& .cardContentPadding": {
      padding: "0px 0px 42px 0px"
  },
  "& .orgDetailPadding": {
      padding: "30px 56px 30px 35px"
  },
  "& .contentPadding": {
      padding: "30px 56px 0px 35px"
  },
  "& .cardTitle": {
      fontFamily: "Urbanist",
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "24px",
      color: "#0444352"
  },
  "& .JobDetailTitle": {
    fontFamily: "Urbanist",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "24px",
    color: "#044352"
},
  "& .cardInputLabel": {
      fontFamily: "Urbanist",
      fontSize: "14px",
      fontWeight: 700,
      lineheight: "22px",
      color: "#344054",
      marginBottom: "10px"
  },
  "& .JobDetailLabel": {
    fontFamily: "Urbanist",
    fontSize: "20px",
    fontWeight: 700,
    lineheight: "28px",
    color: "#0F172A",
    marginBottom: "10px"
},
  "& .JobDetailContent":{
    fontFamily: "Urbanist",
    fontSize: "16px",
    fontWeight: 400,
    lineheight: "22px",
    color: "#0F172A",
    marginBottom: "10px"
  },
  "& .errorStyle": {
      color: "#F87171",
      fontFamily: "Urbanist",
      fontSize: "16px",
      fontWeight: 400,
      fontStyle: "italic"
  },
  "& .errorStyleMainbox": {
      display: "flex",
      justifyContent: "space-between"
  },
  "& .companySuccessDialogClose": {
      top: 16,
      position: 'absolute',
      color: "#334155"
  },
  "& .viewDetailButtonsBox": {
      margin: "16px 0px",
      paddingRight: "16px",
      display: "flex",
      justifyContent: "flex-end"
  },
  "& .viewDetailButton": {
      height: "56px",
      fontFamily: "Urbanist",
      fontSize: "16px",
      fontWeight: 700,
      width: "30%",
      padding: "16px 24px",
      gap: "8px",
      borderRadius: "8px",
      opacity: "0px",
      marginLeft: "16px",
      background: "#044352",
      textTransform: "none",
      "&:hover": {
          backgroundColor: "#044352"
      },
  },
  "& .viewDetailButtonArabic": {
      gap: "8px",
      borderRadius: "8px",
      opacity: "0px",
      fontSize: "16px",
      fontWeight: 700,
      width: "120px",
      height: "56px",
      fontFamily: "Urbanist",
      padding: "16px 39.5px 16px 36.5px",
      marginRight: "16px",
      background: "#044352",
      textTransform: "none",
      "&:hover": {
          backgroundColor: "#044352"
      },
  },
  "& .viewDetailButtonsBoxArabic": {
      display: "flex",
      justifyContent: "flex-end",
      paddingLeft: "16px",
      margin: "16px 0px"
  },
  "& .companySuccessImageBox": {
      padding: "10px 0px",
      display: "flex",
      justifyContent: "center"
  },
  "& .companySuccessDialogTitle": {
      fontFamily: "Urbanist",
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "32px",
      margin: "8px 16px",
      color: "#1E1E1E"
  },
  "& .companyCardContainer": {
      display: "flex",
      justifyContent: "flex-start",
      width: "100%",
      overflowX: "auto",
      scrollSnapType: "x mandatory",
      scrollBehavior: "smooth",
      padding: "0px 8px",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
          display: "none",
      },
  },
  "& .companyCardContainerArabic": {
    flexDirection: "row-reverse",
  },
  "& .chipContainer": {
      borderTop: '1px solid #CBD5E1',
      padding: "12px 16px",
  },
  "& .chip": {
      border: '1px solid #CBD5E1',
      margin: "12px 12px 12px 0px",
      borderRadius: "8px",
      fontFamily: "Urbanist",
      fontSize: "14px",
      fontWeight: 500,
      color: "#475361"
  },
  "& .organizationKeywords": {
      boxShadow: "0px 1px 2px 0px #1018280D",
      fontSie: "16px",
      fontFamily: 'Urbanist',
      border: '1px solid #CBD5E1',
      fontWeight: 400,
      lineHight: "24px",
      borderRadius: "8px",
  },
  "& .orgKeywordInput": {
      border: 0, padding: "24px 12px 24px"
  },
  "& .jdMainBox": {
      display: "flex",
      border: "1px solid #CBD5E1",
      borderRadius: "8px",
      alignItems: "center",
      padding: "20px 5px"
  },
  "& .jdInnerGrid": {
      display: "flex",
      justifyContent: "center"
  },
  "& .deleteJdImg": {
      display: "flex",
      justifyContent: "center"
  },
  "& .jdUploadText": {
      fontFamily: "Urbanist",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      color: "#0F172A"
  },
  "& .jdUploadSubText": {
      fontFamily: "Urbanist",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      color: "#64748B"

  },
  "& .errorClass": {
      border: '1px solid #F87171 !important',
  },
  "& .cultureInputField": {
      boxShadow: "0px 1px 2px 0px #1018280D",
      fontSie: "16px",
      fontFamily: 'Urbanist',
      border: '1px solid #CBD5E1',
      fontWeight: 400,
      lineHight: "24px",
      borderRadius: "8px",
      height: "98px",
      padding: "12px 16px",
      width: '100%',
      color: "#475569",
      "&:focus": {
          borderColor: "#CBD5E1",
          outline: "none",
      }
  },
  "& .companyCultureLimit": {
      display: "flex",
      justifyContent: "flex-end",
      fontSize: "12px",
      fontWeight: 400,
      fontFamily: "Urbanist",
  },
  "& .dropDownStyle": {
      backgroundColor: 'white',
      height: '66px',
      borderRadius: '8px',
      border: '1px solid #CBD5E1',
      fontSie: '16px',
      fontWeight: 700,
      lineHight: "24px",
      fontFamily: 'Urbanist',
      padding: '10px 12px',
      width: '100%',
      color: "#475569"
  },
  "& .dropDownStyleplaceHolder": {
      color: "#DCDCDC", 
      fontSize: 16, 
      fontWeight: 400
  } as React.CSSProperties,
    '& .MuiSelect-iconOpen': {
      transform: 'none',
    },
  "& .sumbmitBtn": {
      height: "65px",
      padding: "10px 16px 10px 16px",
      borderRadius: "4px 0px 0px 0px",
      background: "#044352",
      width: "100%"
  },
  "& .sumbmitText": {
      fontFamily: "Urbanist",
      fontFize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      textTransform: "none",
      color: "#FFFFFF"

  },
  "& input::placeholder, textarea::placeholder": {
      color: "#cccccc"
  },
  "& .progressMainContainer": {
    paddingLeft: "4rem",
    paddingBottom: "80px",
    "@media (max-width: 768px)": {
      paddingLeft: "6rem",
      paddingBottom: "40px",
    },
    "@media (max-width: 480px)": {
      paddingLeft: "5rem",
      paddingBottom: "20px",
    },
  },
  "& .progressContainer": {
    width: "1188px",
    height: "646px",
    boxShadow: "0px 14px 144px 0px #0000001A",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "@media(max-width:1650px)": {
      width: "950px"
    },
    "@media(max-width:1350px)": {
      width: "650px"
    },
    "@media(max-width:970px)": {
      width: "550px"
    },
    "@media(max-width:720px)": {
      width: "450px"
    },
    "@media (max-width: 550px)": {
      width: "350px",
    },
    "@media (max-width: 450px)": {
      width: "250px",
    },
    "@media (max-width: 380px)": {
      width: "200px",
    },
  },
  "& .progressImage": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "100%", 
    height: "auto", 
    "@media (max-width: 768px)": {
      paddingBottom: "20px",
    },
  },
  "& .dotsProgress": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "30px",
    gap: "7.5px",
    "@media (max-width: 768px)": {
      gap: "5px",
    },
    "@media (max-width: 480px)": {
      gap: "3px",
    },
  },
  "& .linearProgressBar": {
    width: "359px",
    paddingTop: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "@media (max-width: 768px)": {
      width: "80%",
    },
    "@media (max-width: 480px)": {
      width: "100%",
    },
  },
  "& .linearProgress": {
    height: "10px",
    width: "100%",
    backgroundColor: "#E2E8F0",
    borderRadius: "5px",
    "& .MuiLinearProgress-bar": {
      backgroundColor: "#059669",
    },
  },
  "& .progressText": {
    color: "#059669",
    fontFamily: "Urbanist",
    fontSize: "2.1rem",
    fontWeight: 700,
    lineHeight: "3.8rem",
    width: "100%",
    textAlign: "center",
    "@media (max-width: 768px)": {
      fontSize: "1.5rem",
    },
    "@media (max-width: 480px)": {
      fontSize: "1rem",
    },
  },
  "& .progressTextRunning": {
    color: "#059669",
    fontFamily: "Urbanist",
    fontSize: "1.25rem",
    lineHeight: "1.75rem",
    fontWeight: 700,
    width: "100%",
    textAlign: "center",
    "@media (max-width: 768px)": {
      fontSize: "1rem",
    },
    "@media (max-width: 480px)": {
      fontSize: "0.875rem",
    },
  },
  "& .ProgressCreationContent": {
    textAlign: "center",
    paddingTop: "62px",
    "@media (max-width: 768px)": {
      paddingTop: "40px",
    },
    "@media (max-width: 480px)": {
      paddingTop: "20px",
    },
  },
  "& .ProgressContent1": {
    color: "#000104",
    fontFamily: "Urbanist",
    fontSize: "1.125rem",
    lineHeight: "1.5rem",
    fontWeight: 700,
    width: "100%",
    textAlign: "center",
    "@media (max-width: 768px)": {
      fontSize: "1rem",
    },
    "@media (max-width: 620px)": {
      fontSize: "0.875rem",
    },
  },
  "& .ProgressContent2": {
    color: "#000104",
    fontFamily: "Urbanist",
    fontSize: "1rem",
    lineHeight: "1.5rem",
    fontWeight: 400,
    width: "100%",
    textAlign: "center",
    "@media (max-width: 768px)": {
      fontSize: "0.875rem",
    },
    "@media (max-width: 620px)": {
      fontSize: "0.75rem",
    },
  },
  "& .emptyScreenMessage": {
    color: "#8A96A8",
    fontFamily: "Urbanist",
    fontSize: "1rem",
    lineHeight: "1.5rem",
    fontWeight: 400,
    width: "100%",
    textAlign: "center",
    "@media (max-width: 768px)": {
      fontSize: "0.875rem",
    },
    "@media (max-width: 620px)": {
      fontSize: "0.75rem",
    },
  },
  "& .jobCreationbutton": {
        top:"32px",
        padding: "10px 40px",
        width:"218px",
      borderRadius: "4px",
      background: "#044352",
  },
  "& .jobCreationText":{
    color: "#FFFFFF",
    fontFamily: "Urbanist",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight:"28px",
    textTransform:"none",
  },
  "& .emptyText": {
    color: "#000104",
    fontFamily: "Urbanist",
    fontSize: "30px",
    lineHeight: "40px",
    fontWeight: 700,
    width: "100%",
    textAlign: "center",
  }
})

// Customizable Area Start