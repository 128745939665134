Object.defineProperty(exports, "__esModule", {
  value: true,
});
// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.gradeAPiEndPoint = "bx_block_question_bank/grades";
exports.downloadTemplateAPiEndPoint =
  "bx_block_question_bank/question_banks/download_template?grade_id=";
exports.uploadTemplateAPiEndPoint = "bx_block_question_bank/question_banks";
exports.questionBankObjectListAPiEndPoint =
  "bx_block_question_bank/questions/question_bank_wise_questions?question_bank_id=";
exports.questionBankListAPiEndPoint = "bx_block_question_bank/question_banks";
exports.ageListAPiEndPoint = "age_groups";
exports.exampleAPiMethod = "POST";
exports.multiPartAPiMethod = "multipart/form-data";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "QuestionBank";
exports.labelBodyText = "QuestionBank Body";
exports.noDataFound = "No Data Found";
exports.btnExampleTitle = "CLICK ME";
exports.listOfQuestionBank = "List of Question Bank";
exports.QuestionBankTitle = "Question Bank";
exports.Url =
  "https://prominlabs-225755-ruby.b225755.dev.eastus.az.svc.builder.cafe";

exports.colorLightGrayStatus = "rgb(236, 246, 255)";
exports.colorLightPink = "rgb(245,189,191)";
exports.errorText = "Required field";
exports.getApiMethodType = "GET";
exports.postApiMethodType = "POST";
exports.deleteApiMethodType = "DELETE";
exports.getCompetencyApiEndPoint = "bx_block_cfchatgptcompetencymapgeneration/competencies";
exports.createQuestionsApiEndPoint = "bx_block_questionbank2/question_banks";
exports.questionListApiEndPoint = "bx_block_questionbank2/question_banks";

// Customizable Area End
