import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    selectedTab: string;
    anchorEl: HTMLElement | null;
    viewMoreAnchorEl: HTMLElement | null;
    selectedRowId: string | null;
    selectedViewRowId: string | null;
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class QuestionBankDashboardController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            selectedTab: "",
            anchorEl: null,
            viewMoreAnchorEl: null,
            selectedRowId: null,
            selectedViewRowId: null
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
    }

    handleTabSelction = (tabText: string) => {
        this.setState({ selectedTab: tabText }, () => {
            let route = "";
            if (this.state.selectedTab === "CreateNewQuestion") {
                route = "QuestionBankCreation";
            }
            const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
            navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), route);
            navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(navigateMsg);
        })
    }

    handleMenuOpen = (event: React.MouseEvent<HTMLElement>, rowId: string) => {
        this.setState({
            anchorEl: event.currentTarget,
            selectedRowId: rowId
        });
    };

    handleViewMoreMenuOpen = (event: React.MouseEvent<HTMLElement>, rowId: string) => {
        this.setState({
            viewMoreAnchorEl: event.currentTarget,
            selectedViewRowId: rowId
        });
    };
    handleViewMoreMenuClose = () => {
        this.setState({
            viewMoreAnchorEl: null,
            selectedViewRowId: null
        });
    };

    handleMenuClose = () => {
        this.setState({
            anchorEl: null,
            selectedRowId: null
        });
    };

    // Customizable Area End
}