import React, { useRef, useState } from "react";
import { Box, Typography, Button, styled, TextField,IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { closeIcon,rightCheck,wrongCheck } from "./assets";
import { WithTranslation, withTranslation } from "react-i18next";

interface Props extends WithTranslation {
    onClose: () => void;
    headingPopup:String; 
    currentPasswordTitle: String;
    currentPasswordError?: String;
    newPasswordTitle: String;
    newPasswordError?: String;
    confirmPasswordTitle: String;
    confirmPasswordError?: String;
    cancelButtonText: String;
    buttonText:String;
    handlCurrentApi: (value: string) => void;
    handleCurrentResponse: boolean;
    IsItEnglish?: boolean;
    onOpen: (password: {currentPassword: string;
        newPassword: string;
        confirmPassword: string
      }) => void;
}

const ChangePasswordModal = (props: Props) => {
    const { onClose, headingPopup, IsItEnglish, currentPasswordTitle, currentPasswordError, newPasswordTitle, newPasswordError,
         confirmPasswordTitle, confirmPasswordError, cancelButtonText, buttonText, onOpen, handlCurrentApi,handleCurrentResponse } = props;
    const [showPassword, setShowPassword] = useState({ current: false, new: false, confirm: false });
    const [passwords, setPasswords] = useState({ currentPassword: "", newPassword: "", confirmPassword: "" });
    const [validation, setValidation] = useState({ hasUpperCase: false, hasLowerCase: false, hasNumber: false, hasSpecialChar: false, hasMinLength: false, });
    const [touched, setTouched] = useState({ currentPasswordTouched: false, newPasswordTouched: false, confirmPasswordTouched: false });

    const handleClickShowPassword = (field: "current" | "new" | "confirm") => {
        setShowPassword((prev) => ({ ...prev, [field]: !prev[field] }));
    };
    console.log("handleCurrentResponse",handleCurrentResponse)
    const handleBlur = (field: "currentPassword" | "newPassword" | "confirmPassword") => {
        setTouched((prev) => ({ ...prev, [`${field}Touched`]: true }));
    };

    const debounceTimer = useRef<any>(null);


    const handleChangePassword = (field: "currentPassword" | "newPassword" | "confirmPassword", value: string) => {
        setPasswords((prev) => ({ ...prev, [field]: value }));
        if (field === "currentPassword") {
            if (debounceTimer.current) {
                clearTimeout(debounceTimer.current);
            }

            debounceTimer.current = setTimeout(() => {
                handlCurrentApi(value);
            }, 2000);
        }
        if (field === "newPassword") handlePasswordChange(value);
    };

    const handlePasswordChange = (value: string) => {
        setValidation({
            hasUpperCase: /[A-Z]/.test(value),
            hasLowerCase: /[a-z]/.test(value),
            hasNumber: /[0-9]/.test(value),
            hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value),
            hasMinLength: value.length >= 8,
        });
    };

    const validateForm = () => {
        setTouched({ currentPasswordTouched: true, newPasswordTouched: true, confirmPasswordTouched: true });
        const isNewPasswordValid = passwords.newPassword.length >= 8 && Object.values(validation).every(Boolean);
        return passwords.currentPassword && isNewPasswordValid && passwords.confirmPassword === passwords.newPassword;
    };

    const handleButtonClick = () => {
        if (!handleCurrentResponse) {
            setTouched((prev) => ({ ...prev, currentPasswordTouched: true }));
            return;
        }
        if (validateForm()) {
            onOpen(passwords);
        } 
    };

    const getPasswordStrength = () => {
        const { hasUpperCase, hasLowerCase, hasNumber, hasSpecialChar } = validation;
        const criteriaCount = [hasUpperCase, hasLowerCase, hasNumber, hasSpecialChar].filter(Boolean).length;
    
        if (criteriaCount <= 1) return { text: "Weak Password", color: "#DC2626" };
        if (criteriaCount === 2 || criteriaCount === 3) return { text: "Medium Password", color: "#F59E0B" };
        if (criteriaCount === 4) return { text: "Strong Password", color: "#059669" };
        return { text: "", color: "" };
    };

    const isCurrentPasswordError = touched.currentPasswordTouched && !passwords.currentPassword;
    const showCurrentPasswordInvalid = Boolean(
        touched.currentPasswordTouched &&
        passwords.currentPassword &&
        !handleCurrentResponse
    );
    const isNewPasswordError = touched.newPasswordTouched && !passwords.newPassword;
    const isConfirmPasswordError = touched.confirmPasswordTouched && (!passwords.confirmPassword || passwords.confirmPassword !== passwords.newPassword);
    const isConfirmPasswordMatch = touched.confirmPasswordTouched && passwords.confirmPassword === passwords.newPassword;

    const TraslationFuncation = (key: string) => {
        return props.i18n.t(`PasswordModel.${key}`);
    };

    return (
<MainWrapper style={{ direction: IsItEnglish ? "ltr" : "rtl" }}>
            <Box style={{ width: "100%" }}>
                <Box className="paper">
                    <Box className="mainLayout">
                        <Typography className="headerTitle">{headingPopup}</Typography>
                        <Box className="closeIconBox" onClick={onClose}>
                            <img src={closeIcon.default} className="closeIcon" alt="close" />
                        </Box>
                    </Box>
                    <Box className="divider"></Box>

                    <Box className="currentPasswordBox">
                        <Box display="flex" marginBottom={"10px"} justifyContent="space-between">
                            <Typography className="currentPasswordText">{currentPasswordTitle}</Typography>
                            {isCurrentPasswordError ? (
                                    <Typography className="currentPasswordError">{currentPasswordError}</Typography>
                                ) : 
                                showCurrentPasswordInvalid && (
                                    <Typography className="currentPasswordError">
                                        {TraslationFuncation("PasswordIsInvalid")}
                                    </Typography>
                                )}
                        </Box>
                        <TextField
                            type={showPassword.current ? "text" : "password"}
                            fullWidth
                            value={passwords.currentPassword}
                            onChange={(e) => handleChangePassword("currentPassword", e.target.value)}
                            onBlur={() => {handleBlur("currentPassword");
                            
                            }}
                            error={isCurrentPasswordError || showCurrentPasswordInvalid}
                            placeholder={TraslationFuncation("EnterYourCurrentPassword")}
                            InputProps={{
                                endAdornment: (
                                    <IconButton onClick={() => handleClickShowPassword("current")}>
                                        {showPassword.current ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                )
                            }}
                        />
                    </Box>

                    <Box className="currentPasswordBox">
                        <Box display="flex" marginBottom={"10px"} justifyContent="space-between">
                            <Typography className="currentPasswordText">{newPasswordTitle}</Typography>
                            {isNewPasswordError ? (
            <Typography className="currentPasswordError">{newPasswordError}</Typography>
        ) : (
            passwords.newPassword && (
                <Typography className="passwordStrengthText" style={{ color: getPasswordStrength().color }}>
                    {getPasswordStrength().text}                                 
                    </Typography>
            )
        )}
                        </Box>
                        <TextField
                            type={showPassword.new ? "text" : "password"}
                            fullWidth
                            value={passwords.newPassword}
                            onChange={(e) => handleChangePassword("newPassword", e.target.value)}
                            onBlur={() => handleBlur("newPassword")}
                            error={isNewPasswordError}
                            placeholder={TraslationFuncation("EnterYourNewPassword")}
                            InputProps={{
                                endAdornment: (
                                    <IconButton onClick={() => handleClickShowPassword("new")}>
                                        {showPassword.new ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                )
                            }}
                        />
                        <Box marginTop={"10px"}>
                        {touched.newPasswordTouched && (
        <>
            <Typography className="passwordContain">{TraslationFuncation("YourPasswordMustContain")}</Typography>
            <Box display="flex" flexDirection="column" gap="5px">
                <Box display="flex" alignItems="center">
                    <img 
                        src={validation.hasUpperCase ? rightCheck.default : wrongCheck.default} 
                        alt={validation.hasUpperCase ? "valid" : "invalid"} 
                        style={{ marginRight: 8 }} 
                    />
                    {TraslationFuncation("AtLeastOneCapitalLetter")}
                </Box>
                <Box display="flex" alignItems="center">
                    <img 
                        src={validation.hasLowerCase ? rightCheck.default : wrongCheck.default} 
                        alt={validation.hasLowerCase ? "valid" : "invalid"} 
                        style={{ marginRight: 8 }} 
                    />
                    {TraslationFuncation("AtLeastOneLowercaseLetter")}
                </Box>
                <Box display="flex" alignItems="center">
                    <img 
                        src={validation.hasNumber ? rightCheck.default : wrongCheck.default} 
                        alt={validation.hasNumber ? "valid" : "invalid"} 
                        style={{ marginRight: 8 }} 
                    />
                    {TraslationFuncation("AtLeastOneNumber")}
                </Box>
                <Box display="flex" alignItems="center">
                    <img 
                        src={validation.hasSpecialChar ? rightCheck.default : wrongCheck.default} 
                        alt={validation.hasSpecialChar ? "valid" : "invalid"} 
                        style={{ marginRight: 8 }} 
                    />
                    {TraslationFuncation("AtLeastOneSpecialCharacter")}
                </Box>
                <Box display="flex" alignItems="center">
                <img 
                src={validation.hasMinLength ? rightCheck.default : wrongCheck.default} 
                alt={validation.hasMinLength ? "valid" : "invalid"} 
                style={{ marginRight: 8 }} 
                />
                {TraslationFuncation("AtLeast8Characters")}
                </Box>
            </Box>
        </>
    )}
                        </Box>
                    </Box>

                    <Box className="currentPasswordBox">
                        <Box display="flex" marginBottom={"10px"} justifyContent="space-between">
                            <Typography className="currentPasswordText">{confirmPasswordTitle}</Typography>
                            {isConfirmPasswordError ? (
            <Typography className="currentPasswordError">
                {passwords.confirmPassword !== passwords.newPassword ? TraslationFuncation("PasswordMismatch") : confirmPasswordError}
            </Typography>
        ) : (
            isConfirmPasswordMatch && (
                <Typography className="passwordMatchText">{TraslationFuncation("PasswordMatch")}</Typography>
            )
        )}
                        </Box>
                        <TextField
                            type={showPassword.confirm ? "text" : "password"}
                            fullWidth
                            value={passwords.confirmPassword}
                            onChange={(e) => handleChangePassword("confirmPassword", e.target.value)}
                            onBlur={() => handleBlur("confirmPassword")}
                            error={isConfirmPasswordError}
                            placeholder={TraslationFuncation("ConfirmYourNewPassword")}
                            InputProps={{
                                endAdornment: (
                                    <IconButton onClick={() => handleClickShowPassword("confirm")}>
                                        {showPassword.confirm ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                )
                            }}
                        />
                    </Box>

                    <Box className="divider"></Box>
                    <Box className="popupContent">
                        <Button className="cancelbutton" onClick={onClose}>{cancelButtonText}</Button>
                        <Button className="button" onClick={handleButtonClick}>{buttonText}</Button>
                    </Box>
                </Box>
            </Box>
        </MainWrapper>
    );
};

export default withTranslation()(ChangePasswordModal);

export const MainWrapper = styled(Box)(({ theme }) => ({
    "& .paper": {
        marginTop: "20px !important",
        marginLeft: "auto",
        marginRight: "auto",
        width: "600px",
        backgroundColor: "#fff",
        borderRadius: "8px 8px 32px 8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "24px",
    },
    "& .mainLayout": {
        width: "92%",
        height: "32px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "24px",
    },
    "& .headerTitle": {
        color: "#000000",
        fontFamily: "Urbanist",
        fontSize: "24px",
        fontWeight: "700",
        display: "flex",
        alignItems: "center",
    },
    "& .closeIconBox": {
        width: "32px",
        height: "32px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    "& .closeIcon": {
        width: "14px",
        height: "14px",
    },
    "& .currentPasswordBox": {
        width:"90%",
    },
    "& .currentPasswordText": {
        color: "#344054",
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: "700",
      },
    "& .currentPasswordError": {
        color: "#F87171",
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: "400",
      },
    "& .passwordMatchText": {
        color: "#059669",
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: "400",
    },
    "& .passwordContain":{
        color: "#0F172A",
        fontFamily: "Urbanist",
        fontSize: "18px",
        fontWeight: "400",
        lineHeight:"26px",
        marginBottom:"10px"
    },
    "& .popupContent": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap:"wrap",
        alignSelf: "end",
        padding: "0 22px 22px 0",
        gap: "15px",
    },
    "& .button": {
        padding: "16px 0",
        backgroundColor: "#044352",
        color: "#FFFFFF",
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: "700",
        textTransform:"none",
        width:"150px",
        borderRadius:"8px",
        "&:hover": {
            backgroundColor: "#044352",
        }
    },
    "& .cancelbutton": {
        padding: "16px 0",
        color: "#044352",
        backgroundColor: "#F1F5F9",
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: "700",
        textTransform:"none",
        width:"150px",
        borderRadius:"8px",
        boxShadow: "0px 4px 4px 0px #00000040",
    },
    "& .divider":{
        width:"100%",
        border:"1px solid #E2E8F0"
    },

    [theme.breakpoints.down('md')]: {
        "& .paper": {
            width: "85%",
            marginTop: "20px",
            padding: "0 10px",
        },
        "& .boxLayout": {
            padding: "0 20px",
        },
        "& .headerTitle": {
            fontSize: "20px",
        },
    },

    [theme.breakpoints.down('sm')]: {
        "& .paper": {
            width: "90%",
            marginTop: "10px",
            padding: "0 8px",
        },
        "& .boxLayout": {
            padding: "0 16px",
        },
        "& .headerTitle": {
            fontSize: "18px",
        },
    },
    "& input::placeholder, TextField::placeholder": {
        color: "#A6B0BF",
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight:"24px"
    }
}));
