// App.js - WEB
import React, { Component, useEffect } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import i18n from 'i18next';
import 'react-calendar/dist/Calendar.css';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet,useNavigate, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import CandidateDashboard from "../../blocks/dashboard/src/CandidateDashboard.web";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import LandingPageWeb from "../../blocks/landingpage/src/LandingPage.web";
import {DashboardWeb as Dashboard} from "../../blocks/dashboard/src/Dashboard.web";
import AutomaticFormCreation from "../../blocks/automaticformcreation/src/AutomaticFormCreation";
import Analytics from "../../blocks/analytics/src/Analytics";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import ViewCompetency from "../../blocks/cfchatgptcompetencymapgeneration/src/ViewCompetency.web";
import ViewDictionary from "../../blocks/cfchatgptcompetencymapgeneration/src/ViewDictionary.web";
import Dictionary from "../../blocks/cfchatgptcompetencymapgeneration/src/ViewDictionary";
import CompetencyDashboard from "../../blocks/cfchatgptcompetencymapgeneration/src/CompetencyDashboard";
import Creation from "../../blocks/cfchatgptcompetencymapgeneration/src/DictionaryCreation";
import DictionaryCreation from "../../blocks/cfchatgptcompetencymapgeneration/src/DictionaryCreation.web";
import CompetencyDictionaryDashboard from "../../blocks/cfchatgptcompetencymapgeneration/src/CompetencyDictionaryDashboard.web";
import Cfchatgptcompetencymapgeneration from "../../blocks/cfchatgptcompetencymapgeneration/src/Cfchatgptcompetencymapgeneration";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import EmailAccountRegistrationWeb, {EmailAccountRegistrationWebWeb} from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import EmailAccountLoginBlock, {EmailAccountLoginBlockWeb} from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Cfchatgptintegration9 , {Cfchatgptintegration9Web} from "../../blocks/cfchatgptintegration9/src/Cfchatgptintegration9.web";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import LanguageOptions from "../../blocks/languageoptions/src/LanguageOptions";
import { ContactUsWeb as Contactus } from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Notifications from "../../blocks/notifications/src/Notifications";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import AssessmentTest from "../../blocks/assessmenttest/src/AssessmentTest";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import UserProfileBasic from "../../blocks/user-profile-basic/src/UserProfileBasicBlock.web";
import EditUserProfile from "../../blocks/user-profile-basic/src/EditUserProfile.web";
import { CompanyDetailsWeb as CompanyDetails } from "../../blocks/user-profile-basic/src/CompanyDetails.web";
import { ViewCompanyDetailsWeb as ViewCompanyDetails } from "../../blocks/user-profile-basic/src/ViewCompanyDetails.web";
import AllUsersDashboard from "../../blocks/user-profile-basic/src/AllUsersDashboard.web";
import UserCreation from "../../blocks/user-profile-basic/src/UserCreation.web";
import QuestionBankDashboard from "../../blocks/questionbank/src/QuestionBankDashboard.web";
import Questions from "../../blocks/questionbank/src/QuestionBankDashboard";
import QuestionBankCreation from "../../blocks/questionbank/src/QuestionBankCreation.web";
import ListingQuestionBank from "../../blocks/questionbank/src/ListingQuestionBank.web";
import ListQuestions from "../../blocks/questionbank/src/ListingQuestionBank";
import QuestionCreation from "../../blocks/questionbank/src/QuestionBankCreation";
import ViewUserProfile from "../../blocks/user-profile-basic/src/ViewUserProfile.web";
import Cfquestionnaireandpersonalizedprogramgeneration from "../../blocks/cfquestionnaireandpersonalizedprogramgeneration/src/Cfquestionnaireandpersonalizedprogramgeneration";
import QuestionBank from "../../blocks/questionbank/src/QuestionBank";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import ForgotPassword, {ForgotPasswordWeb} from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import { Settings2Web as Settings2 } from "../../blocks/settings2/src/Settings2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Cfpromptengineeringfordataworkflowintegration31 from "../../blocks/cfpromptengineeringfordataworkflowintegration31/src/Cfpromptengineeringfordataworkflowintegration31";
import Cfchatgptintegrationforknowledgetest from "../../blocks/cfchatgptintegrationforknowledgetest/src/Cfchatgptintegrationforknowledgetest";
import Servicespecificsettingsadmin2 from "../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import LanguageSupport from "../../blocks/languagesupport/src/LanguageSupport";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import WelcomeScreen from "../../blocks/languagesupport/src/WelcomeScreen"
import { getStorageData } from '../../framework/src/Utilities';


const routeMap = {
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
LandingPage:{
  component:LandingPageWeb,
  path:"/"
},
Dashboard:{
  component:Dashboard,
  path:"/Dashboard"
},
AutomaticFormCreation:{
 component:AutomaticFormCreation,
path:"/AutomaticFormCreation"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Cfchatgptcompetencymapgeneration:{
 component:Cfchatgptcompetencymapgeneration,
path:"/Cfchatgptcompetencymapgeneration"},
CompetencyDashboard:{
  component:CompetencyDashboard,
 path:"/CompetencyDashboard"}, 
  Creation:{
  component:Creation,
 path:"/Creation"},
 DictionaryCreation:{
  component:DictionaryCreation,
 path:"/DictionaryCreation"}, 
 CompetencyDictionaryDashboard:{
  component:CompetencyDictionaryDashboard,
 path:"/CompetencyDictionaryDashboard"},
 ViewCompetency:{
  component:ViewCompetency,
 path:"/ViewCompetency"}, 
 ViewDictionary:{
  component:ViewDictionary,
 path:"/ViewDictionary"}, 
 Dictionary:{
  component:Dictionary,
 path:"/Dictionary"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
EmailAccountRegistrationWeb:{
  component:EmailAccountRegistrationWebWeb,
 path:"/EmailAccountRegistrationWeb"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlockWeb,
path:"/signin"},
EmailAccountRegistrationWeb:{
 component:EmailAccountRegistrationWebWeb,
path:"/signup"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Cfchatgptintegration9:{
 component:Cfchatgptintegration9Web,
path:"/Cfchatgptintegration9"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
CandidateDashboard:{
  component: CandidateDashboard,
  path:"/CandidateDashboard"
},
LanguageOptions:{
 component:LanguageOptions,
path:"/LanguageOptions"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
UserProfileBasic:{
  component:UserProfileBasic,
 path:"/UserProfileBasic"}, 
 EditUserProfile:{
  component:EditUserProfile,
 path:"/EditUserProfile"}, 
 CompanyDetails:{
  component:CompanyDetails,
 path:"/CompanyDetails"}, 
 AllUsersDashboard:{
  component:AllUsersDashboard,
 path:"/AllUsersDashboard"}, 
 UserCreation:{
  component:UserCreation,
 path:"/UserCreation"},
QuestionBankDashboard:{
  component:QuestionBankDashboard,
 path:"/QuestionBankDashboard"},  
 Questions:{
  component:Questions,
 path:"/Questions"}, 
 QuestionBankCreation:{
  component:QuestionBankCreation,
 path:"/QuestionBankCreation"},  
 QuestionCreation:{
  component:QuestionCreation,
 path:"/QuestionCreation"}, 
 ListingQuestionBank:{
  component:ListingQuestionBank,
 path:"/ListingQuestionBank"},
 ListQuestions:{
  component:ListQuestions,
 path:"/ListQuestions"},
 ViewUserProfile:{
  component:ViewUserProfile,
 path:"/ViewUserProfile"}, 
 ViewCompanyDetails:{
  component:ViewCompanyDetails,
 path:"/ViewCompanyDetails"}, 
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
AssessmentTest:{
 component:AssessmentTest,
path:"/AssessmentTest"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
Cfquestionnaireandpersonalizedprogramgeneration:{
 component:Cfquestionnaireandpersonalizedprogramgeneration,
path:"/Cfquestionnaireandpersonalizedprogramgeneration"},
QuestionBank:{
 component:QuestionBank,
path:"/QuestionBank"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
ForgotPassword:{
 component:ForgotPasswordWeb,
path:"/change_password"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Cfpromptengineeringfordataworkflowintegration31:{
 component:Cfpromptengineeringfordataworkflowintegration31,
path:"/Cfpromptengineeringfordataworkflowintegration31"},
Cfchatgptintegrationforknowledgetest:{
 component:Cfchatgptintegrationforknowledgetest,
path:"/Cfchatgptintegrationforknowledgetest"},
Servicespecificsettingsadmin2:{
 component:Servicespecificsettingsadmin2,
path:"/Servicespecificsettingsadmin2"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
LanguageSupport:{
 component:LanguageSupport,
path:"/LanguageSupport"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');


function App() {
  const location = useLocation(); 
  const navigate = useNavigate();
  const isAuthenticate = async() => {
    const tokenMeta = await getStorageData('signInResponse', true);
    const token = tokenMeta?.meta?.token;
    const tokenRegex = /^[a-zA-Z0-9-_]+\.[a-zA-Z0-9-_]+\.[a-zA-Z0-9-_]+$/;
    const publicRoutes = ["/","/signin" , "/signup", "/change_password"];
    const isValidToken = token && tokenRegex.test(token);
    const isPublicRoute = publicRoutes.includes(location.pathname);
    if (!isValidToken && !isPublicRoute) {
      return navigate("/signin")
    }
    return true;
  }

  if (!isAuthenticate()) {
    return null;
  }

  useEffect(async () => {
    const language = await getStorageData("language");
    const selectedLanguage = language.toString().toLowerCase() === "عربي" ? "ar" : "en";

    await i18n.changeLanguage(selectedLanguage);
  }, []);

  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw', overflowX: "hidden"}}>
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;